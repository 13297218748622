/**
 * @generated SignedSource<<720124f8c4ca18ded9e09a6a53ab2580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type enum_users_axe_suspended_state_enum = "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "SUSPENDED_BY_USER_AND_ADMIN" | "%future added value";
export type createAxeDialogButton_suspendedStateQuery$variables = {
  id: string;
};
export type createAxeDialogButton_suspendedStateQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly suspended_state: enum_users_axe_suspended_state_enum | null | undefined;
      };
    }>;
  };
};
export type createAxeDialogButton_suspendedStateQuery = {
  response: createAxeDialogButton_suspendedStateQuery$data;
  variables: createAxeDialogButton_suspendedStateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "usersConnection",
    "kind": "LinkedField",
    "name": "users_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suspended_state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAxeDialogButton_suspendedStateQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAxeDialogButton_suspendedStateQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4a969aff73fdcaa6ed5f3daabab9d986",
    "id": null,
    "metadata": {},
    "name": "createAxeDialogButton_suspendedStateQuery",
    "operationKind": "query",
    "text": "query createAxeDialogButton_suspendedStateQuery(\n  $id: String!\n) {\n  users_connection(where: {id: {_eq: $id}}) {\n    edges {\n      node {\n        id\n        suspended_state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c7c4ebe24ef480719e1dddb6fd60856";

export default node;
