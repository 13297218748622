import {
  Enum_Axe_Product_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Premiumtype_Enum,
  Enum_Axe_Callput_Enum
} from '../../../../../../../gql'
import pick from 'lodash/pick'
import { StrategyFormValues } from '../../axeStrategyForm'
import { leg } from '../../../../createAxeDialogButton'

const strangleKeys: (keyof StrategyFormValues)[] = [
  'buySell',
  'ccyPair',
  'expiryDate',
  'tenor',
  'volatility',
  'notional',
  'notionalCurrency',
  'premiumCurrency'
]

export default function generateStrangleValues(values: StrategyFormValues) {
  const strategyValues = pick(values, strangleKeys)

  const sharedLegValues = {
    ...leg,
    ...strategyValues,
    cut: 'NY',
    product: Enum_Axe_Product_Enum.Vanilla,
    hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
    premiumType: Enum_Axe_Premiumtype_Enum.Forward,
    minimumNotionalAmount: 0,
    pricingVolatility: strategyValues.volatility
  }

  return {
    legs: [
      {
        ...sharedLegValues,
        strike: values.strike1 as string,
        callPut: Enum_Axe_Callput_Enum.Put
      },
      {
        ...sharedLegValues,
        strike: values.strike2 as string,
        callPut: Enum_Axe_Callput_Enum.Call
      }
    ]
  }
}
