/**
 * @generated SignedSource<<b4d39ca524b5678d7178b92742dcd424>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type enum_axe_buysell_enum = "BUY" | "SELL" | "%future added value";
export type enum_axe_callput_enum = "CALL" | "PUT" | "%future added value";
export type enum_axe_ccypair_enum = "AUDCAD" | "AUDJPY" | "AUDNZD" | "AUDUSD" | "BRLJPY" | "BRLMXN" | "CADCHF" | "CADJPY" | "CHFJPY" | "CHFNOK" | "CHFSEK" | "CNHJPY" | "EURAUD" | "EURBRL" | "EURCAD" | "EURCHF" | "EURCNH" | "EURCZK" | "EURDKK" | "EURGBP" | "EURHKD" | "EURHUF" | "EURIDR" | "EURINR" | "EURJPY" | "EURKRW" | "EURMXN" | "EURMYR" | "EURNOK" | "EURNZD" | "EURPHP" | "EURPLN" | "EURRUB" | "EURSEK" | "EURSGD" | "EURTRY" | "EURTWD" | "EURUSD" | "EURZAR" | "GBPAUD" | "GBPBRL" | "GBPCAD" | "GBPCHF" | "GBPHKD" | "GBPJPY" | "GBPNOK" | "GBPNZD" | "GBPSEK" | "GBPUSD" | "HKDJPY" | "JPYKRW" | "MXNJPY" | "NOKJPY" | "NOKSEK" | "NZDCAD" | "NZDJPY" | "NZDUSD" | "SEKJPY" | "TRYJPY" | "TRYZAR" | "USDBRL" | "USDCAD" | "USDCHF" | "USDCLP" | "USDCNH" | "USDCOP" | "USDCZK" | "USDHKD" | "USDHUF" | "USDIDR" | "USDILS" | "USDINR" | "USDJPY" | "USDKRW" | "USDMXN" | "USDMYR" | "USDNOK" | "USDPHP" | "USDPLN" | "USDRUB" | "USDSEK" | "USDSGD" | "USDTRY" | "USDTWD" | "USDZAR" | "%future added value";
export type enum_axe_hedgetype_enum = "NDF" | "forward" | "spot" | "%future added value";
export type enum_axe_premiumtype_enum = "forward" | "spot" | "%future added value";
export type enum_axe_product_enum = "vanilla" | "%future added value";
export type enum_currency_notional_enum = "AUD" | "BRL" | "CAD" | "CHF" | "CLP" | "CNH" | "COP" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "IDR" | "ILS" | "INR" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "TWD" | "USD" | "ZAR" | "%future added value";
export type enum_currency_premium_enum = "AUD" | "BRL" | "CAD" | "CHF" | "CLP" | "CNH" | "COP" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "IDR" | "ILS" | "INR" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "TWD" | "USD" | "ZAR" | "%future added value";
export type enum_tenor_enum = "O_N" | "t_10M" | "t_10Y" | "t_11M" | "t_1M" | "t_1W" | "t_1Y" | "t_2D" | "t_2M" | "t_2W" | "t_2Y" | "t_3D" | "t_3M" | "t_3W" | "t_3Y" | "t_4D" | "t_4M" | "t_4Y" | "t_5D" | "t_5M" | "t_5Y" | "t_6D" | "t_6M" | "t_6Y" | "t_7M" | "t_7Y" | "t_8M" | "t_8Y" | "t_9M" | "t_9Y" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type createAxeDialogButtonFragment$data = {
  readonly axe_authors: ReadonlyArray<{
    readonly __typename: "axe_authors";
    readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonAuthorFragment" | "profileBadgeAxeAuthorFragment">;
  }>;
  readonly axe_legs: ReadonlyArray<{
    readonly buySell: enum_axe_buysell_enum | null | undefined;
    readonly callPut: enum_axe_callput_enum | null | undefined;
    readonly ccyPair: enum_axe_ccypair_enum;
    readonly cut: string | null | undefined;
    readonly deliveryDate: any | null | undefined;
    readonly delta: any | null | undefined;
    readonly expiryDate: any | null | undefined;
    readonly forward: any | null | undefined;
    readonly gamma: any | null | undefined;
    readonly hedgeType: enum_axe_hedgetype_enum | null | undefined;
    readonly minimumNotionalAmount: any | null | undefined;
    readonly notional: any | null | undefined;
    readonly notionalCurrency: enum_currency_notional_enum | null | undefined;
    readonly premium: number;
    readonly premiumCurrency: enum_currency_premium_enum | null | undefined;
    readonly premiumType: enum_axe_premiumtype_enum | null | undefined;
    readonly pricingVolatility: any | null | undefined;
    readonly product: enum_axe_product_enum | null | undefined;
    readonly spot: any | null | undefined;
    readonly strike: any | null | undefined;
    readonly swaps: any | null | undefined;
    readonly tenor: enum_tenor_enum | null | undefined;
    readonly vega: any | null | undefined;
    readonly volatility: any | null | undefined;
  }>;
  readonly updatedAt: any;
  readonly " $fragmentType": "createAxeDialogButtonFragment";
};
export type createAxeDialogButtonFragment$key = {
  readonly " $data"?: createAxeDialogButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createAxeDialogButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "axe_authors",
      "kind": "LinkedField",
      "name": "axe_authors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "createAxeDialogButtonAuthorFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "profileBadgeAxeAuthorFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "orderIndex": "asc"
          }
        }
      ],
      "concreteType": "axe_legs",
      "kind": "LinkedField",
      "name": "axe_legs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "product",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ccyPair",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buySell",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiryDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cut",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "strike",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "callPut",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notional",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notionalCurrency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "premium",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "premiumCurrency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "spot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swaps",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forward",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volatility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hedgeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumNotionalAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delta",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gamma",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vega",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pricingVolatility",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "premiumType",
          "storageKey": null
        }
      ],
      "storageKey": "axe_legs(order_by:{\"orderIndex\":\"asc\"})"
    }
  ],
  "type": "axes",
  "abstractKey": null
};

(node as any).hash = "37a0374d10a437f77429a2f060fecbe6";

export default node;
