import ComboBox from '../../../../comboBox/comboBox'
import { Form, useFormikContext } from 'formik'
import Table from '../../../../table/table'
import ButtonGroup from '../../../../buttonGroup'
import Button, { ButtonVariant } from '../../../button/button'
import generateStraddleValues from './strategies/straddle/generateStraddleValues'
import straddleSchema from './strategies/straddle/schema.yup'
import straddleTableRows from './strategies/straddle/tableRows'
import generateStrangleValues from './strategies/strangle/generateStrangleValues'
import strangleSchema from './strategies/strangle/schema.yup'
import strangleTableRows from './strategies/strangle/tableRows'
import * as Yup from 'yup'
import { VanillaFieldRow } from '../../tableRows'
import { InputVariant } from '../../../../input/input'
import { AxeFormStrategy } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import {
  Enum_Axe_Callput_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Premiumtype_Enum
} from '../../../../../gql'

export enum Strategy {
  STRADDLE = 'straddle',
  STRANGLE = 'strangle'
}

export const strategyLabels: Record<Strategy, string> = {
  straddle: 'Straddle',
  strangle: 'Strangle'
}

const strategyOptions = Object.values(Strategy).map((strategy) => ({
  name: strategyLabels[strategy],
  value: strategy
}))

export type StrategyFormValues = Required<
  Pick<
    AxeFormStrategy,
    | 'strategy'
    | 'buySell'
    | 'ccyPair'
    | 'volatility'
    | 'notional'
    | 'notionalCurrency'
    | 'premiumCurrency'
  >
> &
  Partial<
    Pick<
      AxeFormStrategy,
      'strike' | 'strike1' | 'strike2' | 'expiryDate' | 'tenor'
    >
  >

export const allInitialStrategyValues: AxeFormStrategy = {
  strategy: undefined,
  buySell: undefined,
  ccyPair: undefined,
  tenor: undefined,
  expiryDate: undefined,
  strike: undefined,
  strike1: undefined,
  strike2: undefined,
  volatility: undefined,
  notional: undefined,
  notionalCurrency: undefined,
  premiumCurrency: undefined
}

export type GeneratedAxeFormValuesFromStrategy = {
  legs: Array<
    Required<
      Pick<
        AxeFormStrategy,
        | 'buySell'
        | 'ccyPair'
        | 'strike'
        | 'volatility'
        | 'notional'
        | 'notionalCurrency'
        | 'premiumCurrency'
      >
    > &
      Pick<AxeFormStrategy, 'expiryDate' | 'tenor'> & {
        hedgeType: Enum_Axe_Hedgetype_Enum
        premiumType: Enum_Axe_Premiumtype_Enum
        minimumNotionalAmount: number
        pricingVolatility: number
        callPut: Enum_Axe_Callput_Enum
      }
  >
}

export const strategyValuesGenerator: Record<
  Strategy,
  (values: StrategyFormValues) => GeneratedAxeFormValuesFromStrategy
> = {
  straddle: generateStraddleValues,
  strangle: generateStrangleValues
}

export const validationSchema: Record<Strategy, Yup.AnySchema> = {
  straddle: straddleSchema,
  strangle: strangleSchema
}

const strategyRows: Record<Strategy, Array<VanillaFieldRow>> = {
  straddle: straddleTableRows,
  strangle: strangleTableRows
}

type AxeStrategyForm = {
  strategy: Strategy | undefined
}

type AxeStrategyFormProps = {
  onCancel: () => void
}

export default function AxeStrategyForm({ onCancel }: AxeStrategyFormProps) {
  const { values } = useFormikContext<AxeStrategyForm>()

  return (
    <Form className="flex flex-col gap-4" role="form" noValidate>
      <ComboBox
        label="Choose the type of strategy"
        options={[
          { unavailable: true, name: 'Choose strategy' },
          ...strategyOptions
        ]}
        name="strategy"
        variant={InputVariant.STANDARD}
        className="w-2/3"
      />
      <div className="flex flex-col gap-6">
        {values.strategy ? (
          <>
            <Table
              collapseRowDescriptors
              rows={strategyRows[values.strategy]}
            />
            <ButtonGroup
              className="justify-end"
              buttons={[
                <Button
                  key="cancel"
                  type="button"
                  onClick={onCancel}
                  styleVariant={ButtonVariant.SECONDARY}
                >
                  Cancel
                </Button>,
                <Button key="submit" type="submit">
                  Proceed
                </Button>
              ]}
            />
          </>
        ) : null}
      </div>
    </Form>
  )
}
