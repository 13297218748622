import * as Yup from 'yup'
import {
  buySellSchema,
  ccyPairSchema,
  expiryDateOrTenorSchema,
  strikeSchema,
  volatilitySchema,
  notionalSchema
} from '../../../../../../forms/config/schemas/schemas.yup'

const schema = Yup.object().shape({
  buySell: buySellSchema,
  ccyPair: ccyPairSchema,
  expiryDate: expiryDateOrTenorSchema.expiryDate,
  tenor: expiryDateOrTenorSchema.tenor,
  strike1: strikeSchema,
  strike2: strikeSchema,
  volatility: volatilitySchema,
  notional: notionalSchema
})

export default schema
