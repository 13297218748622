import { formatDateFromISO } from '@optaxe/options-utils'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

export const errorMessages = {
  required: 'Field is required',
  tierPricing: {
    pricingVolatility: {
      sell: 'Sell Prices should satisfy  T1 <= T2 <= T3',
      buy: 'Buy Prices should satisfy T1 >= T2 >= T3'
    }
  }
}

export const buySellSchema = Yup.string().required(errorMessages.required)

export const ccyPairSchema = Yup.string().required(errorMessages.required)

export const expiryDateMin = DateTime.now().plus({ day: 1 }).toISODate()

export const expiryDateMax = DateTime.now()
  .plus({ years: 10, day: 2 })
  .toISODate()

const baseExpiryDateSchema = Yup.date()
  .min(
    expiryDateMin,
    `Expiry date must be on or after ${formatDateFromISO(expiryDateMin, 'dd/MM/yyyy')}`
  )
  .max(
    expiryDateMax,
    `Expiry date must be on or before ${formatDateFromISO(expiryDateMax, 'dd/MM/yyyy')}`
  )

export const expiryDateSchema = baseExpiryDateSchema.required(
  errorMessages.required
)

export const expiryDateOrTenorSchema = {
  expiryDate: baseExpiryDateSchema
    .nullable()
    .test(
      'expiryDate-or-tenor',
      'Either expiry date or tenor must be provided',
      function (value) {
        // Access tenor value from sibling field
        const tenor = this.parent.tenor

        // If neither expiryDate nor tenor is provided, raise validation error
        return !!value || !!tenor
      }
    ),
  tenor: Yup.string()
    .nullable()
    .test(
      'tenor-or-expiryDate',
      'Either tenor or expiry date must be provided',
      function (value) {
        // Access expiryDate value from sibling field
        const expiryDate = this.parent.expiryDate

        // If neither tenor nor expiryDate is provided, raise validation error
        return !!value || !!expiryDate
      }
    )
}

export const strikeSchema = Yup.string()
  .required(errorMessages.required)
  .matches(
    // This regular expression is designed to validate input based on several criteria:
    // 1. It matches the string 'dn' or 'Dn'.
    // 2. It matches numeric values with optional commas and decimal points, followed by 'd' or 'D'.
    // 3. It matches 3 digit values with a d
    // 4. It matches the string 'atmf' or 'ATMF'.
    // 5. It matches the string 'atms' or 'ATMS'.
    // 6. It matches the string 'atm' or 'ATM'.
    // 7. It matches the string 'a' or 'A'.
    // 8. It matches values enclosed in parentheses, like '(123)' or '(12.34)'.
    // 9. It matches any number
    // The entire regex is enclosed in a non-capturing group to ensure proper matching.
    /^(?:(?:[dD][nN])|(?:\d{1,3}(?:,\d{3})*(?:\.\d+)?[dD]?)|(?:100)|(?:[aA][tT][mM][fF])|(?:[aA][tT][mM][sS])|(?:[aA][tT][mM])|(?:[aA])|(?:\(\d+(?:\.\d+)?\)))$/,
    'Invalid value. Valid values are delta inputs (xxd), atmf, atms or numeric'
  )

export const volatilitySchema = Yup.number().required(errorMessages.required)

export const notionalSchema = Yup.string()
  .required(errorMessages.required)
  .matches(/^[0-9,]*$/, 'decimal values are not supported')
