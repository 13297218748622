import { Enum_Axe_Buysell_Enum, Enum_Axe_Ccypair_Enum } from '../../../../gql'
import {
  StringEnum,
  VanillaFieldRow
} from '../../../buttons/createAxeDialogButton/tableRows'
import capitalize from 'lodash/capitalize'
import { InputSelectOption } from '../../../../types/inputSelect'
import { expiryDateMin, expiryDateMax } from '../schemas/schemas.yup'

const isUpperCase = (str: string): boolean => /\d|[A-Z]/.test(str)

export const generateOptionsFromStringEnum = <T extends StringEnum>(
  enumType: T
): Array<InputSelectOption> => {
  return Object.values<string>(enumType).map((value) => {
    return {
      name: isUpperCase(value) ? value : capitalize(value),
      type: isUpperCase(value) ? value : capitalize(value),
      value
    }
  })
}

export const buySellRow: VanillaFieldRow = {
  rowDescriptor: 'Direction',
  rowInputProps: {
    name: 'buySell',
    placeholder: 'Select Direction',
    type: 'comboBox',
    options: [
      {
        name: 'Select Direction',
        unavailable: true
      },
      ...generateOptionsFromStringEnum(Enum_Axe_Buysell_Enum)
    ]
  }
}

export const ccyPairRow: VanillaFieldRow = {
  rowDescriptor: 'CCY Pair',
  rowInputProps: {
    name: 'ccyPair',
    placeholder: 'Select Currency Pair',
    type: 'comboBox',
    options: [
      {
        name: 'Select Currency Pair',
        unavailable: true
      },
      ...generateOptionsFromStringEnum(Enum_Axe_Ccypair_Enum)
    ]
  }
}

export const expiryDateRow: VanillaFieldRow = {
  rowDescriptor: 'Expiration Date',
  rowInputProps: {
    name: 'expiryDate',
    placeholder: '',
    type: 'date',
    min: expiryDateMin,
    max: expiryDateMax
  }
}

export const strikeRow: VanillaFieldRow = {
  rowDescriptor: 'Strike',
  rowInputProps: {
    name: 'strike',
    type: 'number',
    step: 0.01,
    placeholder: '',
    min: 0
  }
}

export const volatilityRow: VanillaFieldRow = {
  rowDescriptor: 'Volatility',
  rowInputProps: {
    name: 'volatility',
    type: 'number',
    step: 0.1,
    placeholder: '',
    min: 0,
    suffix: '%'
  }
}

export const notionalRow: VanillaFieldRow = {
  rowDescriptor: 'Notional',
  rowInputProps: {
    currencySelector: true,
    name: 'notional',
    type: 'number',
    step: 1000000,
    placeholder: '',
    min: 0,
    decimalScale: 0,
    useFastKeys: true
  }
}
