/**
 * @generated SignedSource<<9926b9f43b11ef8ad89a70e7fa849c97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type enum_tiers_enum = "t_1" | "t_2" | "t_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type createAxeDialogButtonTiersFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly isRemoved: boolean | null | undefined;
      readonly organizationId: string;
      readonly subOrganizationId: any | null | undefined;
      readonly tier: enum_tiers_enum;
      readonly user_main_tiering_organization: {
        readonly displayName: string;
        readonly id: string;
      } | null | undefined;
      readonly user_main_tiering_subOrganization: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    };
  }>;
  readonly " $fragmentType": "createAxeDialogButtonTiersFragment";
};
export type createAxeDialogButtonTiersFragment$key = {
  readonly " $data"?: createAxeDialogButtonTiersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonTiersFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createAxeDialogButtonTiersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_main_tieringEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "user_main_tiering",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subOrganizationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRemoved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "organizations",
              "kind": "LinkedField",
              "name": "user_main_tiering_organization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "sub_organizations",
              "kind": "LinkedField",
              "name": "user_main_tiering_subOrganization",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "user_main_tieringConnection",
  "abstractKey": null
};
})();

(node as any).hash = "9938dd0ddb754f1e411f2cfaa3ec5001";

export default node;
