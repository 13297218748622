/**
 * @generated SignedSource<<feb220f0a608e1ee5463f4375dbbe470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useSubOrgUserDataSubOrganizationUsersQuery$variables = {
  subOrganizationUUIDs?: ReadonlyArray<any> | null | undefined;
};
export type useSubOrgUserDataSubOrganizationUsersQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly subOrganization: {
          readonly id: string;
        } | null | undefined;
      };
    }>;
  };
};
export type useSubOrgUserDataSubOrganizationUsersQuery = {
  response: useSubOrgUserDataSubOrganizationUsersQuery$data;
  variables: useSubOrgUserDataSubOrganizationUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subOrganizationUUIDs"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "subOrganizationUUIDs"
              }
            ],
            "kind": "ObjectValue",
            "name": "subOrganizationId"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "usersConnection",
    "kind": "LinkedField",
    "name": "users_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "sub_organizations",
                "kind": "LinkedField",
                "name": "subOrganization",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubOrgUserDataSubOrganizationUsersQuery",
    "selections": (v2/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSubOrgUserDataSubOrganizationUsersQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7eb3b27b257ad35a492708553c0fe2f0",
    "id": null,
    "metadata": {},
    "name": "useSubOrgUserDataSubOrganizationUsersQuery",
    "operationKind": "query",
    "text": "query useSubOrgUserDataSubOrganizationUsersQuery(\n  $subOrganizationUUIDs: [uuid!]\n) {\n  users_connection(where: {subOrganizationId: {_in: $subOrganizationUUIDs}}) {\n    edges {\n      node {\n        fullName\n        subOrganization {\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c52a5e8b42fcdf1956c09dd50758df45";

export default node;
