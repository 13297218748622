/* eslint-disable relay/must-colocate-fragment-spreads */
/* eslint-disable relay/unused-fields */
'use client'

import React from 'react'
import Button from '../button/button'
import Dialog, { SizeVariant } from '../../dialog/dialog'
import { Formik, FormikProps, useFormikContext } from 'formik'
import CreateAxeForm from '../../forms/createAxe/createAxe'
import InputStateContextProvider from '../../../context/inputStateContext/inputStateContext'
import WorkerContextProvider from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import {
  graphql,
  PreloadedQuery,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader
} from 'react-relay/hooks'
import { DateTime } from 'luxon'
import classNames from 'classnames'
import {
  axes_insert_input,
  createAxeDialogButtonMutation,
  enum_axe_depoccy1daycount_enum,
  enum_axe_depoccy2daycount_enum
} from './__generated__/createAxeDialogButtonMutation.graphql'
import {
  AxeFormData,
  AxeFormLegData
} from '../../dialog/updateAxeDialog/updateAxeDialog'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import { createAxeDialogButtonQuery } from './__generated__/createAxeDialogButtonQuery.graphql'
import { useUser } from '@auth0/nextjs-auth0/client'
import Stepper from '../../stepper/stepper'
import CreateAxeTiers from '../../forms/createAxe/createAxeTiers'
import { Tier } from '../../tierSelector/tierSelector'
import {
  getRemovedData,
  getTierData
} from '../../../app/counterparties/components/userTierComponent'
import { createAxeDialogButtonTiersFragment$key } from './__generated__/createAxeDialogButtonTiersFragment.graphql'
import useUpdateSearchParams from '../../../hooks/useUpdateSearchParams/useUpdateSearchParams'
import ConfirmCancelAxeDialog from '../../forms/createAxe/ConfirmCancelAxeDialog'
import { useEnvVariablesContext } from '../../../context/variablesContext/variablesContext'
import { hasOptaxeAdminRole } from '../../../utils/roles/roles'
import {
  getSubOrgOnClient,
  subOrgKeyFE
} from '../../../utils/getSubOrg/getSubOrg'
import useSubOrgUserRef from '../../tierSelector/hooks/useSubOrgUserRef'
import {
  Enum_Axe_Product_Enum,
  Enum_Users_Axe_Suspended_State_Enum
} from '../../../gql'
import { tableRows, tableRowGreeks } from './tableRows'
import FormDataProcessor from '../../formDataProcessor/formDataProcessor'
import useResetForm from '../../../hooks/useResetForm/useResetForm'
import useCreateAxeStepper from './hooks/useCreateAxeStepper/useCreateAxeStepper'
import AxeStrategyForm from './components/axeStrategyForm/axeStrategyForm'
import axeStrategyFormFdpConfig from './components/axeStrategyForm/formDataProcessorConfig'
import isFunction from 'lodash/isFunction'
import MarketDataLoader from '../../forms/rfq/marketDataLoader/marketDataLoader'
import suspendedStateCheck from '../../../utils/suspendedStateCheck/suspendedStateCheck'
import { createAxeDialogButton_suspendedStateQuery$data } from './__generated__/createAxeDialogButton_suspendedStateQuery.graphql'
import { enum_axe_state_enum } from '../../../app/dashboard/trade/components/axeTable/__generated__/axeTableConnectionQueryFragment.graphql'

export const CreateAxeDialogButtonAuthorFragment = graphql`
  fragment createAxeDialogButtonAuthorFragment on axe_authors {
    user {
      id
      subOrganizationId
    }
  }
`

export const CreateAxeDialogButtonFragment = graphql`
  fragment createAxeDialogButtonFragment on axes {
    updatedAt
    axe_authors {
      __typename
      ...createAxeDialogButtonAuthorFragment
      ...profileBadgeAxeAuthorFragment
    }
    axe_legs(order_by: { orderIndex: asc }) {
      product
      ccyPair
      buySell
      tenor
      expiryDate
      deliveryDate
      cut
      strike
      callPut
      notional
      notionalCurrency
      premium
      premiumCurrency
      spot
      swaps
      forward
      volatility
      hedgeType
      minimumNotionalAmount
      delta
      gamma
      vega
      pricingVolatility
      premiumType
    }
  }
`

export const CreateAxeDialogButtonAxeLegsFragment = graphql`
  fragment createAxeDialogButtonAxeLegsFragment on axe_legs {
    updatedAt
    product
    ccyPair
    buySell
    tenor
    expiryDate
    deliveryDate
    cut
    strike
    callPut
    notional
    notionalCurrency
    premium
    premiumCurrency
    spot
    swaps
    forward
    volatility
    hedgeType
    minimumNotionalAmount
    delta
    gamma
    vega
    pricingVolatility
    premiumType
  }
`

export const leg: AxeFormLegData = {
  product: Enum_Axe_Product_Enum.Vanilla,
  ccyPair: undefined as unknown as AxeFormLegData['ccyPair'],
  buySell: undefined,
  tenor: undefined,
  expiryDate: undefined,
  deliveryDate: undefined,
  cut: undefined,
  strike: undefined,
  callPut: undefined,
  notional: undefined,
  notionalCurrency: undefined,
  premium: undefined as unknown as AxeFormLegData['premium'],
  premiumCurrency: undefined,
  premiumDate: undefined,
  premiumType: undefined,
  spot: undefined,
  swaps: undefined,
  forward: undefined,
  volatility: undefined,
  pricingVolatility: undefined,
  pricingVolatilityT2: undefined,
  pricingVolatilityT3: undefined,
  hedgeType: undefined,
  minimumNotionalAmount: undefined,
  delta: undefined,
  gamma: undefined,
  vega: undefined
}

export const initialValues: AxeFormData = {
  id: undefined,
  axeAuthor: undefined,
  tiers: [],
  legs: [leg]
}

export const createAxeMutation = graphql`
  mutation createAxeDialogButtonMutation(
    $axe: axes_insert_input!
    $axePricingTiers: [axe_tier_pricing_insert_input!]!
    $userTiers: [user_tiers_insert_input!]!
  ) {
    insert_axes_one(object: $axe) {
      id
      ...createAxeDialogButtonFragment
    }
    insert_axe_tier_pricing(objects: $axePricingTiers) {
      returning {
        id
      }
    }
    insert_user_tiers(objects: $userTiers) {
      returning {
        id
      }
    }
  }
`

const getISO = (date: Date | string) =>
  typeof date === 'string' ? date : DateTime.fromJSDate(date).toISO()

/**
 * Some values aren't set in the form, but are received from the market API and
 * should be stored. Our API doesn't support the returned values in their raw
 * form, so we modify them slightly
 */
export const parseAxeLegValues = (values: AxeFormLegData) => ({
  ...values,
  tenor: values.tenor || null,
  baseCurrDepo: values.depoCcy1,
  pricingCurrDepo: values.depoCcy2,
  expiryDate: getISO(values.expiryDate),
  premiumDate: getISO(values.premiumDate),
  // premiumType: values.hedgeType,
  spotDate: getISO(values.spotDate),
  strike: Number(values.marketStrike) || Number(values.strike),
  depoCcy1DayCount: values.depoCcy1DayCount?.replace(
    '/',
    '_'
  ) as enum_axe_depoccy1daycount_enum,
  depoCcy2DayCount: values.depoCcy2DayCount?.replace(
    '/',
    '_'
  ) as enum_axe_depoccy2daycount_enum
})

export const parseCreateAxeValues = ({
  axeAuthor,
  axeAuthorSubOrganizationId,
  axeAuthorOrganizationId,
  id
}: {
  axeAuthor: string
  axeAuthorSubOrganizationId: string
  axeAuthorOrganizationId: string
  id: string
}): axes_insert_input => ({
  id,
  axe_authors: {
    data: [
      {
        user_id: getIDFromBase64(axeAuthor || ''),
        subOrganizationId: axeAuthorSubOrganizationId,
        organizationId: axeAuthorOrganizationId
      }
    ]
  }
})

export const CreateAxeDialogButtonQuery = graphql`
  query createAxeDialogButtonQuery(
    $subOrganizationId: uuid!
    $userId: String!
  ) {
    expiry_cuts_connection {
      ...cutComboBoxExpiryCuts
    }
    users_connection(
      where: { subOrganizationId: { _eq: $subOrganizationId } }
    ) {
      ...usersListBoxFragment
    }
    user_main_tiering_connection(where: { ownerId: { _eq: $userId } }) {
      ...createAxeDialogButtonTiersFragment
    }
  }
`

export const CreateAxeDialogButtonTiersFragment = graphql`
  fragment createAxeDialogButtonTiersFragment on user_main_tieringConnection {
    edges {
      node {
        id
        organizationId
        tier
        subOrganizationId
        isRemoved
        user_main_tiering_organization {
          displayName
          id
        }
        user_main_tiering_subOrganization {
          id
          name
        }
      }
    }
  }
`

export const SuspendedStateQuery = graphql`
  query createAxeDialogButton_suspendedStateQuery($id: String!) {
    users_connection(where: { id: { _eq: $id } }) {
      edges {
        node {
          id
          suspended_state
        }
      }
    }
  }
`

interface CreateAxeFormDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentStep: number
  goToStep: (index: number) => void
  isMutationInFlight: boolean
  showStrategyForm?: boolean
  subtitle: string
  title: string
  userQueryRef?: PreloadedQuery<createAxeDialogButtonQuery> | null
}

function CreateAxeFormDialog({
  open,
  setOpen,
  currentStep,
  goToStep,
  isMutationInFlight,
  showStrategyForm,
  subtitle,
  title,
  userQueryRef
}: CreateAxeFormDialogProps) {
  const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] =
    React.useState(false)
  const { dirty } = useFormikContext<AxeFormData>()
  const searchParamsUpdater = useUpdateSearchParams()

  const handleCancel = React.useCallback(
    ({ showConfirmCancelDialog = dirty } = {}) => {
      if (showConfirmCancelDialog) {
        setIsConfirmCancelDialogOpen(true)
      } else {
        setOpen(false)
        searchParamsUpdater({ axeId: '', rfqId: '' })
      }
    },
    [dirty, setOpen, searchParamsUpdater]
  )

  const handleReset = useResetForm()

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={title}
      subtitle={subtitle}
      size={SizeVariant.LARGE_5X}
      fitWidthToContent
    >
      {userQueryRef && (
        <CreateAxeStepper
          userQueryRef={userQueryRef}
          isMutationInFlight={isMutationInFlight}
          showStrategyForm={showStrategyForm}
          currentStep={currentStep}
          goToStep={goToStep}
          onCancel={handleCancel}
        />
      )}
      <ConfirmCancelAxeDialog
        onConfirm={handleReset}
        open={isConfirmCancelDialogOpen}
        setOpen={setIsConfirmCancelDialogOpen}
      />
    </Dialog>
  )
}

interface CreateAxeStepperProps
  extends Omit<
    CreateAxeFormDialogProps,
    'open' | 'setOpen' | 'subtitle' | 'userQueryRef' | 'title'
  > {
  currentStep: number
  userQueryRef: PreloadedQuery<createAxeDialogButtonQuery>
  onCancel: (args?: { showConfirmCancelDialog?: boolean }) => void
}

function CreateAxeStepper({
  currentStep,
  goToStep,
  isMutationInFlight,
  onCancel,
  showStrategyForm,
  userQueryRef
}: CreateAxeStepperProps) {
  const data = usePreloadedQuery(CreateAxeDialogButtonQuery, userQueryRef)
  const expiryCutsConnection = data.expiry_cuts_connection
  const orgDataForTiers = useFragment(
    CreateAxeDialogButtonTiersFragment,
    data.user_main_tiering_connection as createAxeDialogButtonTiersFragment$key
  )
  const subOrgUsersQueryRef = useSubOrgUserRef(orgDataForTiers)
  const tiers: Tier[] = getTierData(orgDataForTiers?.edges)
  const removedSubOrgs = getRemovedData(orgDataForTiers?.edges)

  return (
    <Stepper
      panelIndex={currentStep}
      panels={[
        // Optional strategy step
        showStrategyForm ? (
          <InputStateContextProvider>
            <FormDataProcessor
              allowMarketDataUpdates={false}
              config={axeStrategyFormFdpConfig}
            >
              <AxeStrategyForm onCancel={onCancel} />
            </FormDataProcessor>
          </InputStateContextProvider>
        ) : undefined,
        // Axe form step
        <InputStateContextProvider key={1}>
          <WorkerContextProvider>
            <FormDataProcessor formType="createAxe">
              <MarketDataLoader
                allowMarketDataUpdates={Boolean(showStrategyForm)}
              >
                {({ isLoadingOnRender }) => (
                  <CreateAxeForm
                    isLoadingOnRender={isLoadingOnRender}
                    isMutationInFlight={isMutationInFlight}
                    onCancel={onCancel}
                    tableRows={tableRows}
                    tableRowGreeks={tableRowGreeks}
                    usersConnection={data?.users_connection}
                    expiryCutsConnection={expiryCutsConnection}
                  />
                )}
              </MarketDataLoader>
            </FormDataProcessor>
          </WorkerContextProvider>
        </InputStateContextProvider>,
        // Axe tiers step
        <>
          {subOrgUsersQueryRef && (
            <CreateAxeTiers
              key={2}
              disabled={isMutationInFlight}
              onBack={() => goToStep(-1)}
              onCancel={() => onCancel()}
              removedSubOrgs={removedSubOrgs}
              subOrgUsersQueryRef={subOrgUsersQueryRef}
              tierData={tiers}
            />
          )}
        </>
      ]}
    />
  )
}

export default function DialogButton({
  dataTestId,
  navigationIsOpen,
  showStrategyForm,
  icon: Icon,
  label,
  dialogTitle
}: {
  readonly dataTestId?: string
  readonly navigationIsOpen: boolean
  readonly showStrategyForm?: boolean
  readonly icon: React.ElementType
  readonly label: (values: AxeFormData, open: boolean) => string
  readonly dialogTitle: string | ((currentStep: number) => string)
}) {
  const { user, isLoading } = useUser()
  const [open, setOpen] = React.useState(false)
  const [commitMutation, isMutationInFlight] =
    useMutation<createAxeDialogButtonMutation>(createAxeMutation)
  const formikRef = React.useRef<FormikProps<AxeFormData>>(null)
  const [userQueryRef, userLoadQuery] =
    useQueryLoader<createAxeDialogButtonQuery>(CreateAxeDialogButtonQuery)

  const userId = user?.sub || ''

  const userData = useLazyLoadQuery(SuspendedStateQuery, {
    id: userId
  }) as createAxeDialogButton_suspendedStateQuery$data

  const suspendedState = userData.users_connection.edges[0]?.node
    .suspended_state as
    | Enum_Users_Axe_Suspended_State_Enum
    | enum_axe_state_enum

  const suspended = suspendedStateCheck(suspendedState)

  const variablesContext = useEnvVariablesContext()
  const isOptAxeAdmin = hasOptaxeAdminRole(
    user,
    variablesContext?.rolesKey || ''
  )
  const subOrg = getSubOrgOnClient(user || {})

  React.useEffect(() => {
    if (open && user?.sub && subOrg) {
      userLoadQuery({
        userId: user?.sub,
        subOrganizationId: subOrg
      })
    }

    if (!subOrg && !isLoading) {
      console.error('No subOrganizationId found for user', subOrgKeyFE)
    }
  }, [open, userLoadQuery, user?.sub, isLoading, subOrg])

  const { formik, stepper } = useCreateAxeStepper({
    commitMutation,
    formikRef,
    setOpen,
    subOrg,
    showStrategyForm,
    user
  })

  return (
    // Formik must wrap the dialog to allow for the values to be retained when the dialog is closed
    <Formik
      innerRef={formikRef}
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formik.onSubmit}
      onReset={() => {
        setOpen(false)
        stepper.goToStep(0)
      }}
    >
      {({ values }) => (
        <>
          <Button
            className="w-full flex flex-row gap-2 px-0 justify-center"
            onClick={() => {
              setOpen(!open)
            }}
            navigationButton
            navigationIsOpen={navigationIsOpen}
            data-testid={dataTestId}
            disabled={isOptAxeAdmin || suspended}
          >
            <Icon height="20px" width="20px" />
            <span className={classNames({ hidden: !navigationIsOpen })}>
              {label(values, open)}
            </span>
          </Button>
          <CreateAxeFormDialog
            open={open}
            setOpen={setOpen}
            title={
              isFunction(dialogTitle)
                ? dialogTitle(stepper.currentStep)
                : dialogTitle
            }
            subtitle={`Step ${stepper.currentStep + 1} of ${showStrategyForm ? 3 : 2}`}
            goToStep={stepper.goToStep}
            userQueryRef={userQueryRef}
            isMutationInFlight={isMutationInFlight}
            showStrategyForm={showStrategyForm}
            currentStep={stepper.currentStep}
          />
        </>
      )}
    </Formik>
  )
}
