import get from 'lodash/get'
import merge from 'lodash/merge'
import set from 'lodash/set'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import { InputProps } from '../../../../input/input'
import resetAxeForm from '../resetAxeForm/resetAxeForm'

export default function processOnCcyPairDiff({
  formik: { setErrors, setTouched, values },
  inputStateContext: { dispatch, state },
  path,
  formType
}: Pick<
  OnValuesDiffParams,
  'formik' | 'formType' | 'inputStateContext' | 'path'
>) {
  if (!isLegFieldPath(path) || formType === 'rfq') return null

  const [arrayName, legIndex, fieldName] = path

  const valuesToUpdate = {} as AxeFormData

  const currency = values.legs[0].ccyPair?.slice(0, 3)

  const fieldValue = get(values, [arrayName, 0, fieldName])

  values.legs.forEach((_, index) => {
    // If there is more than one leg, disable the ccyPair field for all legs, including the first leg
    if (values.legs.length > 1) {
      const key = `${arrayName}[${index}].${fieldName}` as InputProps['name']

      if (!state?.[key]?.isDisabled) {
        dispatch({ type: 'set_is_disabled', key })
      }
    }

    if (formType !== 'createAxe') return

    // Update the ccyPair field for all legs including and after the current leg, unless it is the first leg
    if (index !== 0 && legIndex <= index) {
      set(valuesToUpdate, `legs[${index}].ccyPair`, fieldValue)
    }

    // Update the current leg's notionalCurrency and premiumCurrency fields
    if (index === legIndex) {
      set(valuesToUpdate, `legs[${index}].notionalCurrency`, currency)
      set(valuesToUpdate, `legs[${index}].premiumCurrency`, currency)
    }
  })

  // If this is an updateAxe form, there's no need to reset the form, so we can return early.
  if (formType === 'updateAxe') {
    return {
      values: valuesToUpdate
    }
  }

  // When this is the first leg and we have previously set ccyPair and the spot is populated, we want to enable the form to be reset when the ccyPair changes.
  // We need to ensure that there is only one leg, as this process diff will be triggered on the createAxe form when the legs are prepopulated from a strategy.
  if (
    legIndex === 0 &&
    values.legs.length === 1 &&
    values.legs[0].ccyPair &&
    values.legs[0].spot
  ) {
    const axeFormReset = resetAxeForm({
      formik: { setErrors, setTouched, values },
      inputStateContext: { dispatch }
    })

    merge(valuesToUpdate, axeFormReset.values)
  }

  return {
    values: valuesToUpdate
  }
}
