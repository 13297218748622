import {
  Enum_Axe_Callput_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Premiumtype_Enum
} from '../../../../../../../gql'
import pick from 'lodash/pick'
import {
  GeneratedAxeFormValuesFromStrategy,
  StrategyFormValues
} from '../../axeStrategyForm'
import { leg } from '../../../../createAxeDialogButton'

const straddleKeys: (keyof StrategyFormValues)[] = [
  'buySell',
  'ccyPair',
  'expiryDate',
  'tenor',
  'strike',
  'volatility',
  'notional',
  'notionalCurrency',
  'premiumCurrency'
]

export default function generateStraddleValues(values: StrategyFormValues) {
  const strategyValues = pick(values, straddleKeys)

  const sharedLegValues = {
    ...leg,
    ...strategyValues,
    cut: 'NY',
    hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
    minimumNotionalAmount: 0,
    premiumType: Enum_Axe_Premiumtype_Enum.Forward,
    pricingVolatility: strategyValues.volatility
  }

  return {
    legs: [
      {
        ...sharedLegValues,
        callPut: Enum_Axe_Callput_Enum.Put
      },
      {
        ...sharedLegValues,
        callPut: Enum_Axe_Callput_Enum.Call
      }
    ]
  } as GeneratedAxeFormValuesFromStrategy
}
