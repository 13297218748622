import { enum_axe_state_enum } from '../../app/dashboard/trade/components/axeTable/__generated__/axeTableConnectionQueryFragment.graphql'
import { Enum_Users_Axe_Suspended_State_Enum } from '../../gql'

export default function suspendedStateCheck(
  state:
    | Enum_Users_Axe_Suspended_State_Enum
    | enum_axe_state_enum
    | undefined
    | null
) {
  const SUSPENDED_STATES = [
    'SUSPENDED_BY_ADMIN',
    'SUSPENDED_BY_USER',
    'SUSPENDED_BY_USER_AND_ADMIN'
  ]

  if (!state) {
    return false
  }

  return SUSPENDED_STATES.includes(state)
}
