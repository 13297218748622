/**
 * @generated SignedSource<<099cd8ea6412fda14003702f05367108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type enum_color_scheme_enum = "DARK" | "LIGHT" | "%future added value";
export type enum_tiers_enum = "t_1" | "t_2" | "t_3" | "%future added value";
export type enum_users_axe_suspended_state_enum = "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "SUSPENDED_BY_USER_AND_ADMIN" | "%future added value";
export type give_ups_organizations_select_column = "giveupId" | "organizationId" | "%future added value";
export type push_subscriptions_select_column = "createdAt" | "id" | "subscription" | "updatedAt" | "userId" | "%future added value";
export type sub_organizations_select_column = "createdAt" | "displayName" | "id" | "name" | "organizationId" | "updatedAt" | "%future added value";
export type user_main_tiering_select_column = "createdAt" | "id" | "isRemoved" | "organizationId" | "ownerId" | "subOrganizationId" | "tier" | "updatedAt" | "%future added value";
export type user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_and_arguments_columns = "isRemoved" | "%future added value";
export type user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_or_arguments_columns = "isRemoved" | "%future added value";
export type users_select_column = "colorSchemePreference" | "email" | "fullName" | "id" | "last_seen" | "organizationId" | "role" | "subOrganizationId" | "suspended_state" | "%future added value";
export type user_main_tiering_updates = {
  _set?: user_main_tiering_set_input | null | undefined;
  where: user_main_tiering_bool_exp;
};
export type user_main_tiering_set_input = {
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  isRemoved?: boolean | null | undefined;
  organizationId?: string | null | undefined;
  ownerId?: string | null | undefined;
  subOrganizationId?: any | null | undefined;
  tier?: enum_tiers_enum | null | undefined;
  updatedAt?: any | null | undefined;
};
export type user_main_tiering_bool_exp = {
  _and?: ReadonlyArray<user_main_tiering_bool_exp> | null | undefined;
  _not?: user_main_tiering_bool_exp | null | undefined;
  _or?: ReadonlyArray<user_main_tiering_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  isRemoved?: Boolean_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  ownerId?: String_comparison_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  tier?: enum_tiers_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user_main_tiering_organization?: organizations_bool_exp | null | undefined;
  user_main_tiering_subOrganization?: sub_organizations_bool_exp | null | undefined;
};
export type timestamptz_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type uuid_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null | undefined;
  _gt?: boolean | null | undefined;
  _gte?: boolean | null | undefined;
  _in?: ReadonlyArray<boolean> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: boolean | null | undefined;
  _lte?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
  _nin?: ReadonlyArray<boolean> | null | undefined;
};
export type String_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _iregex?: string | null | undefined;
  _is_null?: boolean | null | undefined;
  _like?: string | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nilike?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
  _niregex?: string | null | undefined;
  _nlike?: string | null | undefined;
  _nregex?: string | null | undefined;
  _nsimilar?: string | null | undefined;
  _regex?: string | null | undefined;
  _similar?: string | null | undefined;
};
export type enum_tiers_enum_comparison_exp = {
  _eq?: enum_tiers_enum | null | undefined;
  _in?: ReadonlyArray<enum_tiers_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_tiers_enum | null | undefined;
  _nin?: ReadonlyArray<enum_tiers_enum> | null | undefined;
};
export type organizations_bool_exp = {
  _and?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  _not?: organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  fixConnectionString?: String_comparison_exp | null | undefined;
  fixConnectionUp?: Boolean_comparison_exp | null | undefined;
  fixEnabled?: Boolean_comparison_exp | null | undefined;
  giveups?: give_ups_organizations_bool_exp | null | undefined;
  giveups_aggregate?: give_ups_organizations_aggregate_bool_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  subOrganizations?: sub_organizations_bool_exp | null | undefined;
  subOrganizations_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  users?: users_bool_exp | null | undefined;
  users_aggregate?: users_aggregate_bool_exp | null | undefined;
};
export type give_ups_organizations_bool_exp = {
  _and?: ReadonlyArray<give_ups_organizations_bool_exp> | null | undefined;
  _not?: give_ups_organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<give_ups_organizations_bool_exp> | null | undefined;
  giveup?: give_ups_bool_exp | null | undefined;
  giveupId?: uuid_comparison_exp | null | undefined;
  organization?: organizations_bool_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
};
export type give_ups_bool_exp = {
  _and?: ReadonlyArray<give_ups_bool_exp> | null | undefined;
  _not?: give_ups_bool_exp | null | undefined;
  _or?: ReadonlyArray<give_ups_bool_exp> | null | undefined;
  email?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organizations?: give_ups_organizations_bool_exp | null | undefined;
  organizations_aggregate?: give_ups_organizations_aggregate_bool_exp | null | undefined;
};
export type give_ups_organizations_aggregate_bool_exp = {
  count?: give_ups_organizations_aggregate_bool_exp_count | null | undefined;
};
export type give_ups_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<give_ups_organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: give_ups_organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type Int_comparison_exp = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type sub_organizations_bool_exp = {
  _and?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  _not?: sub_organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  parent_organization?: organizations_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type sub_organizations_aggregate_bool_exp = {
  count?: sub_organizations_aggregate_bool_exp_count | null | undefined;
};
export type sub_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sub_organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: sub_organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type users_bool_exp = {
  _and?: ReadonlyArray<users_bool_exp> | null | undefined;
  _not?: users_bool_exp | null | undefined;
  _or?: ReadonlyArray<users_bool_exp> | null | undefined;
  colorSchemePreference?: enum_color_scheme_enum_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  fullName?: String_comparison_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  last_seen?: timestamp_comparison_exp | null | undefined;
  organization?: organizations_bool_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  pushSubscriptions?: push_subscriptions_bool_exp | null | undefined;
  pushSubscriptions_aggregate?: push_subscriptions_aggregate_bool_exp | null | undefined;
  role?: String_comparison_exp | null | undefined;
  subOrganization?: sub_organizations_bool_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  suspended_state?: enum_users_axe_suspended_state_enum_comparison_exp | null | undefined;
  tierings?: user_main_tiering_bool_exp | null | undefined;
  tierings_aggregate?: user_main_tiering_aggregate_bool_exp | null | undefined;
};
export type enum_color_scheme_enum_comparison_exp = {
  _eq?: enum_color_scheme_enum | null | undefined;
  _in?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_color_scheme_enum | null | undefined;
  _nin?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
};
export type timestamp_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type push_subscriptions_bool_exp = {
  _and?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  _not?: push_subscriptions_bool_exp | null | undefined;
  _or?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  subscription?: jsonb_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  userId?: String_comparison_exp | null | undefined;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null | undefined;
  _contained_in?: any | null | undefined;
  _contains?: any | null | undefined;
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _has_key?: string | null | undefined;
  _has_keys_all?: ReadonlyArray<string> | null | undefined;
  _has_keys_any?: ReadonlyArray<string> | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp = {
  count?: push_subscriptions_aggregate_bool_exp_count | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<push_subscriptions_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: push_subscriptions_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type enum_users_axe_suspended_state_enum_comparison_exp = {
  _eq?: enum_users_axe_suspended_state_enum | null | undefined;
  _in?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_users_axe_suspended_state_enum | null | undefined;
  _nin?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
};
export type user_main_tiering_aggregate_bool_exp = {
  bool_and?: user_main_tiering_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: user_main_tiering_aggregate_bool_exp_bool_or | null | undefined;
  count?: user_main_tiering_aggregate_bool_exp_count | null | undefined;
};
export type user_main_tiering_aggregate_bool_exp_bool_and = {
  arguments: user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type user_main_tiering_aggregate_bool_exp_bool_or = {
  arguments: user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type user_main_tiering_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<user_main_tiering_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type users_aggregate_bool_exp = {
  count?: users_aggregate_bool_exp_count | null | undefined;
};
export type users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<users_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type userTierComponentMutation$variables = {
  objects: ReadonlyArray<user_main_tiering_updates>;
};
export type userTierComponentMutation$data = {
  readonly update_user_main_tiering_many: ReadonlyArray<{
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly isRemoved: boolean | null | undefined;
      readonly organizationId: string;
      readonly subOrganizationId: any | null | undefined;
      readonly tier: enum_tiers_enum;
    }>;
  } | null | undefined> | null | undefined;
};
export type userTierComponentMutation = {
  response: userTierComponentMutation$data;
  variables: userTierComponentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updates",
        "variableName": "objects"
      }
    ],
    "concreteType": "user_main_tiering_mutation_response",
    "kind": "LinkedField",
    "name": "update_user_main_tiering_many",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_main_tiering",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subOrganizationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRemoved",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userTierComponentMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userTierComponentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb2762e3e3243cad9ce8da2f49867d23",
    "id": null,
    "metadata": {},
    "name": "userTierComponentMutation",
    "operationKind": "mutation",
    "text": "mutation userTierComponentMutation(\n  $objects: [user_main_tiering_updates!]!\n) {\n  update_user_main_tiering_many(updates: $objects) {\n    returning {\n      id\n      subOrganizationId\n      tier\n      organizationId\n      isRemoved\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7fadb14ff91637931bd7a0decd83fbd";

export default node;
