/**
 * @generated SignedSource<<d1dfdbc89e606b0475878be0e08d15fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileBadgeUsersFragment$data = {
  readonly fullName: string | null | undefined;
  readonly id: string;
  readonly subOrganizationId: any | null | undefined;
  readonly " $fragmentType": "profileBadgeUsersFragment";
};
export type profileBadgeUsersFragment$key = {
  readonly " $data"?: profileBadgeUsersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileBadgeUsersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileBadgeUsersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subOrganizationId",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "d4ea76d56f681352ca0f7ee94e2e3127";

export default node;
