import set from 'lodash/set'
import { RFQFormValues } from '../../../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import isNonLegFieldPath from '../../../utils/isNonLegFieldPath/isNonLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import {
  calculatePercentage,
  formatPercentage,
  invertSignUnlessInAuthorSubOrg
} from '@optaxe/options-utils'
import { InputProps } from '../../../../input/input'

// Refer to useNotionalRows.ts for the implementation details related to changes in the delta hedge input. This is the
// input that appears when the "Specify Delta Hedge" checkbox is selected.
const processOnSpecifyDeltaHedgeDiff = ({
  formik: { values },
  inputStateContext,
  isUserCounterParty,
  path
}: Pick<
  OnValuesDiffParams,
  'formik' | 'inputStateContext' | 'isUserCounterParty' | 'path'
>) => {
  if (!isNonLegFieldPath(path)) return null

  const rfqValues = values as RFQFormValues
  const valuesToUpdate = {} as RFQFormValues

  if (rfqValues.specifyDeltaHedge) {
    // When checkbox is checked, populate the deltaHedge input
    const netDelta = values.legs.reduce((acc, leg) => acc + (leg.delta || 0), 0)

    set(valuesToUpdate, 'deltaHedge', netDelta)
  } else {
    // Otherwise clear any warning and reset the deltaHedge value
    values.legs.forEach((leg, legIndex) => {
      const key = `legs[${legIndex}].delta` as InputProps['name']

      set(valuesToUpdate, key, leg.bsDelta)

      const partyBasedValue = invertSignUnlessInAuthorSubOrg(
        Number(leg.bsDelta) || 0,
        !isUserCounterParty
      )

      // Although we update the `value` for the delta input, we also need to update `set_party_based_value` to ensure the
      // party-based value displays correctly to the counter-party.
      inputStateContext.dispatch({
        type: 'set_party_based_value',
        key,
        payload: partyBasedValue
      })

      inputStateContext.dispatch({
        type: 'set_suffix',
        key,
        payload: formatPercentage(
          calculatePercentage(partyBasedValue, leg.notional)
        )
      })
    })

    set(valuesToUpdate, 'deltaHedge', undefined)
    inputStateContext.dispatch({ type: 'unset_warning', key: 'deltaHedge' })
  }

  return {
    values: valuesToUpdate
  }
}

export default processOnSpecifyDeltaHedgeDiff
