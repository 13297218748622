import { Enum_Axe_Ccypair_Enum } from '@optaxe/options-utils/dist/gql'

export const ndfCurrencies: Array<Enum_Axe_Ccypair_Enum> = [
  Enum_Axe_Ccypair_Enum.Usdkrw,
  Enum_Axe_Ccypair_Enum.Usdinr,
  Enum_Axe_Ccypair_Enum.Usdidr,
  Enum_Axe_Ccypair_Enum.Usdmyr,
  Enum_Axe_Ccypair_Enum.Usdtwd,
  Enum_Axe_Ccypair_Enum.Usdphp,
  Enum_Axe_Ccypair_Enum.Eurkrw,
  Enum_Axe_Ccypair_Enum.Eurinr,
  Enum_Axe_Ccypair_Enum.Euridr,
  Enum_Axe_Ccypair_Enum.Eurtwd,
  Enum_Axe_Ccypair_Enum.Eurphp,
  Enum_Axe_Ccypair_Enum.Jpykrw,
  Enum_Axe_Ccypair_Enum.Usdbrl,
  Enum_Axe_Ccypair_Enum.Usdcop,
  Enum_Axe_Ccypair_Enum.Usdclp,
  Enum_Axe_Ccypair_Enum.Eurbrl,
  Enum_Axe_Ccypair_Enum.Brlmxn,
  Enum_Axe_Ccypair_Enum.Brljpy,
  Enum_Axe_Ccypair_Enum.Gbpbrl
]
