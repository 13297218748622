import React from 'react'
import {
  Combobox,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
  ComboboxInput
} from '@headlessui/react'
import { useField } from 'formik'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { InputSelectProps, InputSelectOption } from '../../types/inputSelect'
import { InputProps, InputVariant, VanillaFormNames } from '../input/input'
import { useWorkerContext } from '../../context/marketDataWorkerContext/marketDataWorkerContext'
import { tableClassNames } from '../table/table'
import { RFQDialogFormValues } from '../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import removeDuplicateClasses from '../../utils/removeDuplicateClasses/removeDuplicateClasses'

export interface ComboBoxProps<
  T extends string = VanillaFormNames | keyof RFQDialogFormValues
> extends InputSelectProps<T> {
  readonly type?: 'comboBox'
  variant?: InputVariant
  /**
   * The `overrideValue` prop is used to temporarily override the selected option's value without updating the actual
   * Formik field value. This helps in situations where an external event needs to display a different value in the
   * combobox but should not update Formik's values or trigger infinite re-renders. If `overrideValue` is provided,
   * it takes precedence over `fieldValue` and `defaultValue`.
   */
  overrideValue?: string
  /**
   * If `rightAlign` is true, the combobox options will be right-aligned within its container.
   */
  rightAlign?: boolean
  label?: string
}

interface GetSelectedOptionParams {
  options: InputSelectOption[]
  defaultValue: InputSelectOption
  fieldValue?: string
  overrideValue?: string
}

function getSelectedOption({
  options,
  defaultValue,
  fieldValue,
  overrideValue
}: GetSelectedOptionParams) {
  // Determine option based on override value, if present
  const overrideOption = overrideValue
    ? options.find(({ name }) => name === overrideValue)
    : null

  // Determine option based on field value, or use default if field value is empty
  // * if the field value is set to an empty string, the field is reset and so should be set to the default value
  // * this is used when users select options on other fields that should clear the value of this field
  const defaultOrMatchingOption = !fieldValue
    ? defaultValue
    : (options.find(({ value }) => value === fieldValue) ?? options[0])

  // Return override option if it exists, otherwise return default or matching option
  return overrideOption || defaultOrMatchingOption
}

export default function ComboBox<T extends string>({
  options,
  name,
  error,
  disabled,
  className,
  variant = InputVariant.DEFAULT,
  overrideValue,
  rightAlign,
  label
}: ComboBoxProps<T>) {
  const [field, , helper] = useField(name)

  const defaultValue =
    options.find(({ name }) => name === field.value) ?? (options[0] || '')
  const [selected, setSelected] = React.useState<InputSelectOption | undefined>(
    defaultValue
  )
  const [query, setQuery] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { setLastChangedField } = useWorkerContext()
  const selectedValue = selected?.value
  const filteredOptions: Array<InputSelectOption> =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })

  const handleFocus = React.useCallback(() => {
    if (!inputRef.current) return

    if (selected?.unavailable) {
      inputRef.current.value = ''
      setQuery('')
    }
  }, [selected])

  React.useEffect(() => {
    if (!options.length) return

    const newOption = getSelectedOption({
      options,
      defaultValue,
      fieldValue: field.value,
      overrideValue
    })

    setSelected((selected) => {
      return newOption !== selected ? newOption : selected
    })
  }, [options, defaultValue, field.value, overrideValue])

  // send the selected value to formik when it changes unless the field is disabled or the value is overridden
  React.useEffect(() => {
    if (!selectedValue || disabled || overrideValue) return
    helper.setValue(selectedValue)
  }, [disabled, helper, overrideValue, selectedValue])

  const comboBoxProps = {
    defaultValue,
    value: selected,
    onChange: (value: InputSelectOption) => {
      setLastChangedField(field.name as InputProps['name'])
      setSelected(value)
    },
    name,
    as: 'div',
    disabled,
    className: 'h-full'
  } as unknown as typeof Combobox

  const cellClassNames = classNames(
    'py-0',
    tableClassNames.cellHeight,
    tableClassNames.cellPadLeft,
    tableClassNames.cellControlPad
  )

  return (
    <div
      className={classNames(
        {
          'shadow-[0_0_1px_1px] shadow-errorRed': error
        },
        removeDuplicateClasses('w-full relative rounded-md h-full', className)
      )}
    >
      <Combobox {...comboBoxProps}>
        <label
          htmlFor={field.name}
          className={classNames({
            'text-[14px] mb-2 block': variant === InputVariant.STANDARD,
            'visually-hidden': [
              InputVariant.CELL,
              InputVariant.DEFAULT
            ].includes(variant)
          })}
        >
          {label || field.name}
        </label>
        <ComboboxButton className="relative w-full cursor-default text-left h-full">
          <ComboboxInput
            id={field.name}
            ref={inputRef}
            onChange={(event) => setQuery(event.target.value)}
            onFocus={handleFocus}
            displayValue={(option: InputSelectOption) => option?.name}
            className={classNames(
              `block truncate relative w-full cursor-default text-left focus:border-indigo-500 focus:ring-indigo-500 focus:rounded-none bg-transparent rounded-md h-full`,
              {
                'text-[12px] border-none': [
                  InputVariant.CELL,
                  InputVariant.DEFAULT
                ].includes(variant)
              },
              { 'py-2 pl-3 pr-10': variant === InputVariant.DEFAULT },
              {
                'dark:bg-overlayBlue dark:border-overlayBlue border-borderGray border px-4 text-[14px]':
                  variant === InputVariant.STANDARD
              },
              { [cellClassNames]: variant === InputVariant.CELL },
              {
                'text-textLightGray dark:text-textLightGray bg-transparent':
                  selected?.unavailable || disabled
              },
              {
                'dark:text-white text-black bg-white dark:bg-darkBlue':
                  !selected?.unavailable && !disabled
              }
            )}
          />
          {!disabled && (
            <span
              className={classNames(
                'pointer-events-none absolute inset-y-0 right-[2px] flex items-center',
                { 'right-0 pr-2': variant === InputVariant.STANDARD },
                { 'right-0 pr-2': variant === InputVariant.DEFAULT },
                { 'right-[2px]': variant === InputVariant.CELL }
              )}
            >
              <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </ComboboxButton>

        {!!filteredOptions.length && (
          <ComboboxOptions
            className={classNames(
              'absolute z-10 mt-1 min-w-full max-h-60 overflow-auto bg-white dark:text-white dark:bg-darkBlueGray py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-[12px]',
              { 'right-0': rightAlign }
            )}
          >
            {filteredOptions.map((option, optionId) => (
              <ComboboxOption
                key={optionId}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-4 ${
                    active
                      ? 'bg-fadedBlue dark:bg-royalBlue dark:text-white text-deepBlue'
                      : 'text-textGray dark:text-white'
                  }`
                }
                value={option}
                disabled={option.unavailable}
              >
                {({ selected }) => (
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-normal'
                    } ${option.unavailable ? 'opacity-40' : ''}`}
                  >
                    {option.name}
                  </span>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </Combobox>
    </div>
  )
}
