import React from 'react'
import { Axe_Legs, Axes } from '../../gql'
import { useFormikContext } from 'formik'
import { useInputStateContext } from '../inputStateContext/inputStateContext'
import toast from 'react-hot-toast'
import {
  tableRowGreeks,
  tableRows
} from '../../components/buttons/createAxeDialogButton/tableRows'
import { InputProps } from '../../components/input/input'
import { PostMessage } from '../../workers/marketDataWorker/marketDataWorker'
import merge from 'lodash/merge'
import convertFlatToNestedValues from './utils/convertFlatToNestedValues/convertFlatToNestedValues'
import { AxeFormData } from '../../components/dialog/updateAxeDialog/updateAxeDialog'
import getRatioAdjustedDeltaValues from '../../utils/getRatioAdjustedDeltaValues/getRatioAdjustedDeltaValues'
import isEmpty from 'lodash/isEmpty'
import { extractArrayReferenceFromFieldName } from '../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'
import logBrowserError from '../../utils/logBrowserError/logBrowserError'

interface GetMarketValuesParams {
  values: AxeFormData
  refreshAll?: boolean
  refreshField?: InputProps['name']
}

interface WorkerContextProps {
  worker: Worker | null
  workerFetching: boolean
  workerError: boolean
  getMarketValues: ({
    values,
    refreshAll,
    refreshField
  }: GetMarketValuesParams) => void
  getCachedMarketValues?: (values: Axes) => void
  resetMarketValues: () => void
  setLastChangedField: (field: InputProps['name']) => void
  resetWorkerCache: () => void
}

export type MarketDataValues = Record<InputProps['name'], number | string>

export type AxeFormValues = Axes & {
  legs: Axe_Legs[]
  deltaHedge?: number
  specifyDeltaHedge?: boolean
}

export const WorkerContext = React.createContext<WorkerContextProps>({
  worker: null,
  workerFetching: false,
  workerError: false,
  getMarketValues: () => undefined,
  getCachedMarketValues: undefined,
  resetMarketValues: () => undefined,
  setLastChangedField: () => undefined,
  resetWorkerCache: () => undefined
})

export const useWorkerContext = () => React.useContext(WorkerContext)

const getTablePropsForInput = (key: string) =>
  [...tableRows, ...tableRowGreeks].find((row) =>
    key.endsWith(row.rowInputProps.name as string)
  )

const adjustDeltaValuesInlineWithDeltaHedge = (
  values: AxeFormValues,
  marketDataValues: MarketDataValues
): MarketDataValues => {
  // calculate the adjusted delta values if we have the delta hedge.
  // When the delta hedge is present, we calculate the ratio of the delta values across and the legs
  // and adjust the values so that the summary delta = deltaHedge
  const { deltaHedge } = values
  const deltaValues = Object.entries(marketDataValues).filter(([key]) =>
    key.endsWith('delta')
  )

  if (deltaHedge && !isEmpty(deltaValues)) {
    const deltaKeys = deltaValues.map(([key]) => key)
    const ratioAdjustedDeltaValues = getRatioAdjustedDeltaValues(
      deltaValues.map(([, value]) => value as number),
      deltaHedge
    )
    // Assign the adjusted delta values to the original leg key
    deltaKeys.forEach((key, index) => {
      marketDataValues[key as keyof MarketDataValues] =
        ratioAdjustedDeltaValues[index]
    })
  }

  return marketDataValues
}

export default function WorkerContextProvider({
  children
}: React.PropsWithChildren) {
  const workerRef = React.useRef<Worker | null>(null)
  const { state, dispatch } = useInputStateContext()
  const { values, setValues } = useFormikContext<AxeFormValues>()
  const [workerFetching, setWorkerFetching] = React.useState(false)
  const [workerError, setWorkerError] = React.useState(false)
  const [marketDataValues, setMarketDataValues] =
    React.useState<MarketDataValues>()
  const [lastChangedField, setLastChangedField] =
    React.useState<InputProps['name']>()

  const processMarketDataValues = React.useCallback(
    (marketDataValues: MarketDataValues) => {
      return Object.entries(
        adjustDeltaValuesInlineWithDeltaHedge(values, marketDataValues)
      ).reduce<MarketDataValues>((acc, [key, value]) => {
        // Set flag on input fields that contain a fetched value. This value might not be displayed if the user has overtyped it
        // however we want to keep track of it so that we can reset the field to the fetched value if the user clicks the refresh button
        const fieldKey = values[key as keyof Axes]?.value ? `${key}.value` : key

        // If the form field can be "refreshed", we want to tell the app that it contains a fetched value
        // and therefor can be refreshed
        if (getTablePropsForInput(key)?.rowRefresh) {
          dispatch({
            type: 'set_contains_fetched_value',
            key: fieldKey as InputProps['name']
          })
        }

        const { arrayIndex: sourceLegIndex } =
          extractArrayReferenceFromFieldName(lastChangedField)

        const isOverTyped =
          state[fieldKey]?.overTyped && state[fieldKey]?.containsFetchedValue
        const isLastChangedField = lastChangedField?.endsWith(key)

        const isSpotAndLastChangedFieldIsSpot =
          sourceLegIndex === 0 &&
          key.endsWith('spot') &&
          lastChangedField?.endsWith('spot')

        // Skip updating the value if the key has been overtyped, unless this key was the last one changed or if a
        // refresh is required for all fields.
        if (
          isOverTyped &&
          !isLastChangedField &&
          !lastChangedField?.endsWith('refreshAll') &&
          // This allows for spots on leg 1 or leg 2 to be updated when the leg 0 spot is (and they are overtyped)
          !isSpotAndLastChangedFieldIsSpot
        ) {
          return acc
        }

        // Skip updating `delta` values if the user has opted to manage the delta hedge themselves, as these values then
        // represent the adjusted delta hedge split across legs. The `bsDelta` still requires updating, so we only exit
        // early for delta-specific fields.
        if (values.specifyDeltaHedge === true && fieldKey.endsWith('delta')) {
          return acc
        }

        // Round the value to the nearest whole number if the key is 'premium' or 'delta'.
        if (fieldKey.endsWith('premium') || fieldKey.endsWith('delta')) {
          acc[key as keyof MarketDataValues] = Math.round(value as number)

          return acc
        }

        acc[key as keyof MarketDataValues] = value

        return acc
      }, {} as MarketDataValues)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, lastChangedField, JSON.stringify(state), values] // TODO - remove JSON.stringify(state) from dependency array
  )

  // When a worker fetches and sets new market data, trigger this effect to update the form state with the new values.
  // The process involves several steps to handle specific field interactions - such as spot/swaps/forwards and currency pair updates.
  React.useEffect(() => {
    if (!marketDataValues) return

    const processedMarketDataValues = processMarketDataValues(marketDataValues)
    const updatedValues = convertFlatToNestedValues(processedMarketDataValues)
    const mergedValues = merge({}, values, updatedValues)

    setValues(mergedValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketDataValues])

  const getCachedMarketValues = React.useCallback(
    (values: Axes) => {
      if (!workerRef.current) return

      workerRef.current.postMessage({
        values,
        state,
        useCache: true,
        lastChangedField
      })
    },
    [lastChangedField, state]
  )

  const getMarketValues = React.useCallback(
    ({ values, refreshAll = false, refreshField }: GetMarketValuesParams) => {
      const newLastChangedField = refreshAll
        ? ('legs[0].refreshAll' as InputProps['name'])
        : refreshField

      setLastChangedField(newLastChangedField)

      let worker: Worker | null = workerRef.current

      if (!worker) {
        worker = registerWorker()
      }

      if (!worker) {
        logBrowserError('postMessage call made to unregistered worker')

        return
      }

      worker.postMessage({
        values,
        state,
        useCache: false,
        lastChangedField: newLastChangedField,
        refreshAll,
        refreshField: Boolean(refreshField)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(state)]
  )

  const registerWorker = React.useCallback(() => {
    if (!window.Worker) return null

    workerRef.current = new Worker(
      new URL(
        '../../workers/marketDataWorker/marketDataWorker',
        import.meta.url
      )
    )

    return workerRef.current
  }, [])

  React.useEffect(() => {
    if (!workerRef.current) return

    workerRef.current.onmessage = (e: MessageEvent<PostMessage>) => {
      if (e && e.data) {
        const { fetching, formData, error, requestError } = e.data

        if (error) {
          toast.error('Fetching market data failed. Please refresh.', {
            duration: 4000
          })
        }

        // maintain state in context if requestError of fetching return undefined
        setWorkerError((stateRequestError) =>
          requestError === undefined ? stateRequestError : requestError
        )
        setWorkerFetching((stateFetchingValue) =>
          fetching === undefined ? stateFetchingValue : fetching
        )

        formData &&
          setMarketDataValues(
            formData as unknown as Record<InputProps['name'], string>
          )
      }
    }

    return () => {
      workerRef.current?.terminate()
      resetWorkerCache()

      workerRef.current = null
    }
  }, [registerWorker])

  const resetMarketValues = () => setMarketDataValues(undefined)

  const resetWorkerCache = () => {
    if (!workerRef.current) return

    workerRef.current.postMessage({ resetCache: true })
  }

  return (
    <WorkerContext.Provider
      value={{
        workerError,
        workerFetching,
        resetMarketValues,
        worker: workerRef.current,
        getMarketValues,
        getCachedMarketValues,
        setLastChangedField,
        resetWorkerCache
      }}
    >
      {children}
    </WorkerContext.Provider>
  )
}
