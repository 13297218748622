/**
 * @generated SignedSource<<cd90c79f713cd5c5eeb2fd20579677a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type quickAxeGetUserQuery$variables = {
  email: string;
  userId: string;
};
export type quickAxeGetUserQuery$data = {
  readonly user_main_tiering_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonTiersFragment">;
  };
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organizationId: string | null | undefined;
        readonly subOrganizationId: any | null | undefined;
      };
    }>;
  };
};
export type quickAxeGetUserQuery = {
  response: quickAxeGetUserQuery$data;
  variables: quickAxeGetUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subOrganizationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "email"
            }
          ],
          "kind": "ObjectValue",
          "name": "email"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "usersConnection",
  "kind": "LinkedField",
  "name": "users_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "usersEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "users",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "ownerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "quickAxeGetUserQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user_main_tieringConnection",
        "kind": "LinkedField",
        "name": "user_main_tiering_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "createAxeDialogButtonTiersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "quickAxeGetUserQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user_main_tieringConnection",
        "kind": "LinkedField",
        "name": "user_main_tiering_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_main_tieringEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_main_tiering",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tier",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRemoved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "organizations",
                    "kind": "LinkedField",
                    "name": "user_main_tiering_organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_organizations",
                    "kind": "LinkedField",
                    "name": "user_main_tiering_subOrganization",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec07fd56dbfdf4282302994030c4abbc",
    "id": null,
    "metadata": {},
    "name": "quickAxeGetUserQuery",
    "operationKind": "query",
    "text": "query quickAxeGetUserQuery(\n  $email: String!\n  $userId: String!\n) {\n  users_connection(where: {email: {_eq: $email}}) {\n    edges {\n      node {\n        id\n        organizationId\n        subOrganizationId\n      }\n    }\n  }\n  user_main_tiering_connection(where: {ownerId: {_eq: $userId}}) {\n    ...createAxeDialogButtonTiersFragment\n  }\n}\n\nfragment createAxeDialogButtonTiersFragment on user_main_tieringConnection {\n  edges {\n    node {\n      id\n      organizationId\n      tier\n      subOrganizationId\n      isRemoved\n      user_main_tiering_organization {\n        displayName\n        id\n      }\n      user_main_tiering_subOrganization {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "43bf123ab48923542ec0842f04f2ba21";

export default node;
