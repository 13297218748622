import React from 'react'
import ListBox, { ListBoxProps } from '../listBox'
import { useField } from 'formik'
import { usePrevious } from 'react-use'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { Enum_Axe_Callput_Enum } from '../../../gql'
import { buildOptionString } from '@optaxe/options-utils'

const CallPutListBox = ({ fieldArrayPrefix, ...props }: ListBoxProps) => {
  const [ccyPairField] = useField(
    generateFieldName('ccyPair', fieldArrayPrefix)
  )
  const [options, setOptions] = React.useState(props.options)
  const previousCcyPair = usePrevious(ccyPairField.value)
  React.useEffect(() => {
    if (!ccyPairField.value || ccyPairField.value === previousCcyPair) return
    setOptions(
      options.map((option) =>
        option.unavailable
          ? option
          : {
              ...option,
              name: buildOptionString(
                option.type as Enum_Axe_Callput_Enum,
                ccyPairField.value
              )
            }
      )
    )
  }, [ccyPairField.value, options, previousCcyPair])

  return <ListBox {...props} options={options} />
}

export default CallPutListBox
