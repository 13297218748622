/**
 * @generated SignedSource<<9f56739bf315409d9a629ffa0ceb3373>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type enum_tiers_enum = "t_1" | "t_2" | "t_3" | "%future added value";
export type userTierComponentQuery$variables = {
  id: string;
};
export type userTierComponentQuery$data = {
  readonly organizations_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"sendQueryDialogFragment">;
  };
  readonly user_main_tiering_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isRemoved: boolean | null | undefined;
        readonly organizationId: string;
        readonly subOrganizationId: any | null | undefined;
        readonly tier: enum_tiers_enum;
        readonly user_main_tiering_organization: {
          readonly displayName: string;
          readonly id: string;
        } | null | undefined;
        readonly user_main_tiering_subOrganization: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      };
    }>;
  };
  readonly users_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"suspendUserDialogFragment">;
  };
};
export type userTierComponentQuery = {
  response: userTierComponentQuery$data;
  variables: userTierComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "ownerId"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "user_main_tieringConnection",
  "kind": "LinkedField",
  "name": "user_main_tiering_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_main_tieringEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "user_main_tiering",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "organizationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "subOrganizationId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRemoved",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "sub_organizations",
              "kind": "LinkedField",
              "name": "user_main_tiering_subOrganization",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "organizations",
              "kind": "LinkedField",
              "name": "user_main_tiering_organization",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userTierComponentQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "suspendUserDialogFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "organizationsConnection",
        "kind": "LinkedField",
        "name": "organizations_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "sendQueryDialogFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userTierComponentQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "organizationsConnection",
        "kind": "LinkedField",
        "name": "organizations_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "organizationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "organizations",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "092da522aea0a824f59ed0057f20644d",
    "id": null,
    "metadata": {},
    "name": "userTierComponentQuery",
    "operationKind": "query",
    "text": "query userTierComponentQuery(\n  $id: String!\n) {\n  user_main_tiering_connection(where: {ownerId: {_eq: $id}}) {\n    edges {\n      node {\n        id\n        organizationId\n        subOrganizationId\n        tier\n        isRemoved\n        user_main_tiering_subOrganization {\n          id\n          name\n        }\n        user_main_tiering_organization {\n          id\n          displayName\n        }\n      }\n    }\n  }\n  users_connection(where: {id: {_eq: $id}}) {\n    ...suspendUserDialogFragment\n  }\n  organizations_connection {\n    ...sendQueryDialogFragment\n  }\n}\n\nfragment sendQueryDialogFragment on organizationsConnection {\n  edges {\n    node {\n      displayName\n      id\n    }\n  }\n}\n\nfragment suspendUserDialogFragment on usersConnection {\n  edges {\n    node {\n      fullName\n      email\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6501ba26acd189ccdee068e44d302ed3";

export default node;
