import * as Yup from 'yup'
import { AxeFormLegData } from '../../dialog/updateAxeDialog/updateAxeDialog'
import {
  buySellSchema,
  ccyPairSchema,
  errorMessages,
  expiryDateSchema,
  notionalSchema,
  strikeSchema
} from '../../forms/config/schemas/schemas.yup'

export const tierPricingSchema = Yup.object().shape({
  buySell: Yup.string().required(errorMessages.required),
  pricingVolatility: Yup.number().required(errorMessages.required),
  pricingVolatilityT2: Yup.number()
    .required(errorMessages.required)
    .when('buySell', {
      is: 'SELL',
      then: (schema) =>
        schema.min(
          Yup.ref('pricingVolatility'),
          errorMessages.tierPricing.pricingVolatility.sell
        )
    })
    .when('buySell', {
      is: 'BUY',
      then: (schema) =>
        schema.max(
          Yup.ref('pricingVolatility'),
          errorMessages.tierPricing.pricingVolatility.buy
        )
    }),
  pricingVolatilityT3: Yup.number()
    .required(errorMessages.required)
    .test({
      name: 'is-pricingVolatilityT3-in-range',
      // Validates the value against the other volatility fields based on buySell.
      // Using .test method due to requirement to check multiple volatility fields and using createError to generate custom error message due to the unavailability of the entire schema context in the message function scope.
      test: (value, ctx) => {
        const { buySell, pricingVolatility, pricingVolatilityT2 } = ctx.parent

        const isT3GreaterThanOrEqualTo =
          value >= pricingVolatility && value >= pricingVolatilityT2
        const isT3LessThanOrEqualTo =
          value <= pricingVolatility && value <= pricingVolatilityT2

        // For SELL operations, T3 must exceed or match the other two values.
        // For BUY operations, T3 must be less than or equal to the other two values.
        if (buySell === 'SELL' && !isT3GreaterThanOrEqualTo) {
          return ctx.createError({
            message: errorMessages.tierPricing.pricingVolatility.sell
          })
        } else if (buySell === 'BUY' && !isT3LessThanOrEqualTo) {
          return ctx.createError({
            message: errorMessages.tierPricing.pricingVolatility.buy
          })
        }

        return true
      }
    })
})

export const legSchema = Yup.object().shape<
  Partial<Record<keyof AxeFormLegData, Yup.AnySchema>>
>({
  product: Yup.string().required(errorMessages.required),
  ccyPair: ccyPairSchema,
  buySell: buySellSchema,
  expiryDate: expiryDateSchema,
  cut: Yup.string().required(errorMessages.required),
  callPut: Yup.string().required(errorMessages.required),
  notional: notionalSchema,
  notionalCurrency: Yup.string().required(errorMessages.required),
  spot: Yup.string().required(errorMessages.required),
  strike: strikeSchema,
  swaps: Yup.string().required(errorMessages.required),
  forward: Yup.string().required(errorMessages.required),
  volatility: Yup.string().required(errorMessages.required),
  pricingVolatility: Yup.string().required(errorMessages.required),
  hedgeType: Yup.string().required(errorMessages.required),
  minimumNotionalAmount: notionalSchema.test(
    'is-smaller-than-notional',
    'Minimum notional amount must not be greater than the notional amount',
    function (value) {
      const { notional } = this.parent

      if (!value || !notional) {
        return true
      }

      const minNotionalAmount = parseInt(value.replace(/,/g, ''))
      const notionalAmount = parseInt(notional.replace(/,/g, ''))

      return minNotionalAmount <= notionalAmount
    }
  ),
  premium: Yup.string().required(errorMessages.required),
  premiumCurrency: Yup.string().required(errorMessages.required),
  delta: Yup.string().required(errorMessages.required)
})

/**
 * Validation schema for the create axe form.
 * The ComboBox and ListBox generate hidden input fields with the name of the field
 * suffixed by the key in the object. See `product[name]`.
 * Some of the keys returned by the market data are disabled inputs and should be caught elsewhere
 * rather than prevent a user from submitting because of a field they cannot edit
 */
const schema = Yup.object().shape<Yup.ObjectShape>({
  legs: Yup.array().of(legSchema).required(errorMessages.required),
  axeAuthor: Yup.string().required(errorMessages.required)
})

export default schema
