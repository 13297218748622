import classNames from 'classnames'
import { NumericInputProps } from '../input'
import { useField } from 'formik'
import React from 'react'
import bigDecimal from 'js-big-decimal'
import split from 'lodash/split'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import IncrementInputWrapper from '../incremementInputWrapper/incremementInputWrapper'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { tableClassNames } from '../../table/table'

const removeCharacters = (value: string) => value.replace(/[^dnatmfs\d.]/gi, '')

const restrictCharacters = (
  e: React.ChangeEvent<HTMLInputElement>
): [string, number] => {
  const inputValue = e.target.value

  const [valueToValidate] = split(inputValue, '(')
  const trimmedValueToValidate = valueToValidate.trim()

  const cursor = e.target.selectionStart
  const beforeCursor = trimmedValueToValidate.slice(0, cursor ?? 0)
  const afterCursor = trimmedValueToValidate.slice(
    cursor ?? 0,
    trimmedValueToValidate.length
  )

  const filteredBeforeCursor = removeCharacters(beforeCursor)
  const filteredAfterCursor = removeCharacters(afterCursor)

  let newText = filteredBeforeCursor + filteredAfterCursor
  let newCursorPosition = filteredBeforeCursor.length

  // Format big ints correctly -
  // First unformat the big int
  const parsedNumber = Number(newText.replace(/,/g, ''))

  // then only allow non-decimal numbers
  if (
    typeof parsedNumber === 'number' &&
    !isNaN(parsedNumber) &&
    !newText.includes('.') &&
    newText !== ''
  ) {
    newText = bigDecimal.getPrettyValue(parsedNumber)
    newCursorPosition = newText.length
  }

  return [newText, newCursorPosition]
}

/**
 * Unformat the number to check that 1,000 !== 1000
 */
const areDifferentNumbers = (
  value: string | number,
  marketValue: string | number
) =>
  !Number(value) &&
  Number(marketValue) &&
  Number(value.toString().replace(/,/g, '')) !== Number(marketValue)

/**
 * We want the value string to contain the market value in brackets if it is different than the strike value
 */
const buildValueString = (
  strikeValue: string | number,
  marketStrikeValue: string | number,
  workerError?: boolean
) => {
  const shouldShowMarketStrikeValue =
    marketStrikeValue &&
    strikeValue &&
    !workerError &&
    areDifferentNumbers(strikeValue, marketStrikeValue)

  return `${strikeValue ?? ''}${
    shouldShowMarketStrikeValue ? ` (${marketStrikeValue})` : ''
  }`
}

const getDisplayValueAndFormikValue = (
  strikeValue: string | number,
  marketStrikeValue: string | number,
  workerError: boolean
) => {
  const valueString = buildValueString(
    strikeValue,
    marketStrikeValue,
    workerError
  )

  const userInput = split(valueString, '(')[0].trim()

  return [valueString, userInput]
}

type StrikeProps = Omit<NumericInputProps, 'type'>

/**
 * The strike should initially be populated with the spot value once populated
 * and then manually editable by the user
 */
const Strike = ({ className, fieldArrayPrefix, ...restProps }: StrikeProps) => {
  const [strikeField, , strikeHelper] = useField(restProps.name)
  const [marketStrikeField] = useField(
    generateFieldName('marketStrike', fieldArrayPrefix)
  )
  const inputRef = React.useRef<HTMLInputElement>(null)
  const prevCursorPosition = React.useRef<number | null>(null)
  const { workerError } = useWorkerContext()
  const [value, setValue] = React.useState('')

  const getUserInputEnd = (target: HTMLInputElement) => {
    const [valueToValidate] = split(target.value, '(')
    const trimmedValueEnd = valueToValidate.trim().length

    return trimmedValueEnd
  }

  React.useEffect(() => {
    const [valueString, userInput] = getDisplayValueAndFormikValue(
      strikeField.value,
      marketStrikeField.value,
      workerError
    )

    if (valueString !== value) {
      setValue(valueString)
    }

    if (
      strikeField.value &&
      userInput &&
      strikeField.value !== Number.parseFloat(userInput)
    ) {
      strikeHelper.setValue(userInput, true)
    }
  }, [
    marketStrikeField.value,
    strikeField.value,
    strikeHelper,
    value,
    workerError
  ])

  React.useEffect(() => {
    // This allows us to wait until after the component has updated to reset the cursor position
    window.requestAnimationFrame(() => {
      if (!inputRef.current) return

      inputRef.current.selectionStart = prevCursorPosition.current
      inputRef.current.selectionEnd = prevCursorPosition.current
    })
  }, [value])

  // when we click on the input, we either want to use the current cursor position
  // or move it to the end of the editable part of the input
  const handleCaret = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement
    const [valueToValidate, marketValue] = split(target.value, '(')
    const trimmedValueEnd = valueToValidate.trim().length

    const actualCursorPosition =
      target.selectionStart !== target.selectionEnd
        ? target.selectionEnd
        : target.selectionStart

    const cursorPosition =
      marketValue && (target.selectionStart ?? 0) > trimmedValueEnd
        ? trimmedValueEnd
        : actualCursorPosition

    target.setSelectionRange(cursorPosition, cursorPosition)
  }

  const handleDoubleClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement
    const trimmedValueEnd = getUserInputEnd(target)
    target.setSelectionRange(0, trimmedValueEnd)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const trimmedValueEnd = getUserInputEnd(target)

    if (
      target.selectionStart !== null &&
      target.selectionStart > trimmedValueEnd
    ) {
      window.requestAnimationFrame(() => {
        target.setSelectionRange(trimmedValueEnd, trimmedValueEnd)
      })
    }
  }

  // Restrict the strike input to only allow `dn`, `atmf`, `i`, delta values or floating point numbers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [value, cursorPosition] = restrictCharacters(e)
    prevCursorPosition.current = cursorPosition

    const [, userInput] = getDisplayValueAndFormikValue(
      value,
      marketStrikeField.value,
      workerError
    )

    strikeHelper.setValue(userInput)
  }

  return (
    <IncrementInputWrapper {...restProps} className={className}>
      <input
        ref={inputRef}
        className={classNames(
          className,
          'w-full border-none bg-transparent text-[12px] p-0 disabled:text-textLightGray disabled:cursor-not-allowed',
          tableClassNames.cellHeight,
          tableClassNames.cellPadLeft
        )}
        id={strikeField.name}
        {...restProps}
        {...strikeField}
        value={value}
        onKeyDown={handleKeyDown}
        onDoubleClick={handleDoubleClick}
        onClick={handleCaret}
        onFocus={handleCaret}
        onChange={handleChange}
        type="text"
        defaultValue={undefined}
        role="textbox"
      />
    </IncrementInputWrapper>
  )
}

export default Strike
