import React, { ReactNode } from 'react'
import { useWorkerContext } from '../../../../context/marketDataWorkerContext/marketDataWorkerContext'
import { Timeout } from 'react-number-format/types/types'

type MarketDataLoaderProps = {
  allowMarketDataUpdates: boolean
  children: (props: { isLoadingOnRender: boolean }) => ReactNode
}

// The app will always make a market data request for the axe price when the RFQ dialogue mounts
// so to avoid values changing on the screen without user input, we wait for the fetch to start
// then hide it when it resolves - or max 3 seconds
const useShowMarketDataLoader = (allowMarketDataUpdates: boolean) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [preFetch, setPreFetch] = React.useState(true)
  const { workerFetching } = useWorkerContext()
  const timerId = React.useRef<Timeout | null>(null)

  const startTimer = React.useCallback(() => {
    if (timerId.current) return

    timerId.current = setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  React.useEffect(() => {
    startTimer()

    if (preFetch && workerFetching) {
      setPreFetch(false)
    }

    if (!preFetch && !workerFetching) {
      setIsLoading(false)

      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }
  }, [workerFetching, preFetch, startTimer])

  if (!allowMarketDataUpdates) return false

  return isLoading
}

const MarketDataLoader: React.FC<MarketDataLoaderProps> = ({
  allowMarketDataUpdates,
  children
}) => {
  const isLoadingOnRender = useShowMarketDataLoader(allowMarketDataUpdates)

  return children({ isLoadingOnRender })
}

export default MarketDataLoader
