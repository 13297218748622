import { FieldInputProps } from 'formik'
import { isNdfCurrency } from '../../../../utils/isNdfCurrency/isNdfCurrency'
import {
  VanillaFieldRow,
  tableRows
} from '../../../buttons/createAxeDialogButton/tableRows'
import { ComboBoxProps } from '../../../comboBox/comboBox'
import { VanillaFormNames } from '../../../input/input'
import { tableValueIsString } from '../../../tableRow/tableRow'
import { AllPossibleKeys, Row } from '../../table'
import { enum_axe_ccypair_enum } from '../../../rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { Enum_Axe_Ccypair_Enum } from '../../../../gql'

type ComboBoxFieldRow = VanillaFieldRow & {
  rowInputProps: ComboBoxProps<VanillaFormNames>
}

const hedgeTypeRow = tableRows.find(
  ({ rowInputProps }) => rowInputProps.name === 'hedgeType'
) as ComboBoxFieldRow

// Filters options based on currency pair type: Include only 'NDF' for fix currencies; exclude 'NDF' otherwise.
function filterHedgeTypeOptions(
  row: Row<keyof AllPossibleKeys>,
  ccyPairField: FieldInputProps<enum_axe_ccypair_enum>
) {
  if (
    tableValueIsString(row.rowInputProps) ||
    !['hedgeType'].includes(row.rowInputProps.name)
  )
    return row

  const isAFixCurrency = isNdfCurrency(
    ccyPairField.value as Enum_Axe_Ccypair_Enum
  )

  const filteredOptions = hedgeTypeRow.rowInputProps.options.filter(
    ({ value }) => {
      // Include the placeholder option
      if (!value) return true

      return isAFixCurrency ? value === 'NDF' : value !== 'NDF'
    }
  )

  return {
    ...row,
    rowInputProps: {
      ...row.rowInputProps,
      options: filteredOptions
    }
  }
}

export default filterHedgeTypeOptions
