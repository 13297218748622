import set from 'lodash/set'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import { FormDataProcessorConfig } from '../../../../formDataProcessor/config/config'
import { Strategy } from './axeStrategyForm'

const formDataProcessorConfig: FormDataProcessorConfig = {
  tenor: {
    onValuesDiff: ({ formik: { values } }) => {
      if (!(values as AxeFormData).tenor) return null

      const valuesToUpdate = {} as AxeFormData

      set(valuesToUpdate, 'expiryDate', null)

      return {
        values: valuesToUpdate
      }
    }
  },
  ccyPair: {
    onValuesDiff: ({ formik: { values } }) => {
      const valuesToUpdate = {} as AxeFormData

      const currency = values.ccyPair?.slice(0, 3)

      set(valuesToUpdate, 'notionalCurrency', currency)
      set(valuesToUpdate, 'premiumCurrency', currency)

      return {
        values: valuesToUpdate
      }
    }
  },

  strike: {
    onValuesDiff: ({ formik: { values } }) => {
      const valuesToUpdate = {} as AxeFormData

      if (
        (values as AxeFormData).strategy === Strategy.STRADDLE &&
        (values as AxeFormData).strike?.includes('d')
      ) {
        set(valuesToUpdate, 'strategy', 'strangle')
        set(valuesToUpdate, 'strike', null)
        set(valuesToUpdate, 'strike1', (values as AxeFormData).strike)
        set(valuesToUpdate, 'strike2', (values as AxeFormData).strike)
      }

      return {
        values: valuesToUpdate
      }
    }
  }
}

export default formDataProcessorConfig
