/* eslint-disable  no-unused-vars, camelcase */

import {
  Enum_Axe_Callput_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Product_Enum
} from '../../../gql'
import { Row, RowInputValue } from '../../table/table'
import { ListBoxProps } from '../../listBox/listBox'
import { InputSelectOption } from '../../../types/inputSelect'
import { ComboBoxProps } from '../../comboBox/comboBox'
import { NumericInputProps, VanillaFormNames } from '../../input/input'
import {
  buySellRow,
  ccyPairRow,
  expiryDateRow,
  generateOptionsFromStringEnum,
  notionalRow,
  strikeRow,
  volatilityRow
} from '../../forms/config/tableRows/tableRows'

export interface VanillaFieldRow
  extends Omit<Row<VanillaFormNames>, 'rowInputProps'> {
  rowInputProps:
    | RowInputValue<VanillaFormNames>
    | ListBoxProps
    | ComboBoxProps
    | NumericInputProps
}

export type StringEnum = { [s: string]: string }

// Vanilla should be the first option in the product dropdown
const generateProductOptions = <T extends StringEnum>(
  enumType: T
): Array<InputSelectOption> => {
  const options = generateOptionsFromStringEnum(enumType)
  const filteredOptions = options.filter(
    (option) => option.value !== Enum_Axe_Product_Enum.Vanilla
  )
  filteredOptions.unshift({
    name: 'European Vanilla',
    value: Enum_Axe_Product_Enum.Vanilla
  })

  return filteredOptions
}

export const getTableRow = (name: VanillaFormNames) =>
  tableRows.find((row) => row.rowInputProps.name === name) || null

export const tableRows: Array<VanillaFieldRow> = [
  {
    rowDescriptor: 'Product',
    rowInputProps: {
      name: 'product',
      placeholder: 'Select Product',
      type: 'comboBox',
      options: [
        {
          name: 'Select Product',
          unavailable: true
        },
        ...generateProductOptions(Enum_Axe_Product_Enum)
      ]
    }
  },
  ccyPairRow,
  buySellRow,
  expiryDateRow,
  {
    rowDescriptor: 'Delivery Date',
    rowDisabled: true,
    rowInputProps: {
      name: 'deliveryDate',
      placeholder: '',
      type: 'date'
    }
  },
  {
    rowDescriptor: 'Cut',
    rowInputProps: {
      name: 'cut',
      placeholder: 'Select Cut',
      type: 'comboBox',
      options: []
    }
  },
  strikeRow,
  {
    rowDescriptor: 'Option',
    rowInputProps: {
      name: 'callPut',
      placeholder: 'Select Option',
      type: 'listBox',
      options: [
        {
          name: 'Select Option',
          unavailable: true
        },
        ...generateOptionsFromStringEnum(Enum_Axe_Callput_Enum)
      ]
    }
  },
  notionalRow,
  {
    rowDescriptor: 'Spot',
    rowRefresh: true,
    rowInputProps: {
      name: 'spot',
      type: 'number',
      step: 0.01,
      placeholder: '',
      min: 0
    }
  },
  {
    rowDescriptor: 'Swaps',
    rowRefresh: true,
    rowInputProps: {
      name: 'swaps',
      type: 'number',
      step: 0.01,
      placeholder: ''
    }
  },
  {
    rowDescriptor: 'Forward',
    rowRefresh: true,
    rowInputProps: {
      name: 'forward',
      type: 'number',
      step: 0.01,
      placeholder: '',
      min: 0
    }
  },
  {
    ...volatilityRow,
    rowDescriptor: 'Mid. Volatility'
  },
  {
    rowDescriptor: 'Pricing Volatility',
    rowInputProps: {
      ...volatilityRow.rowInputProps,
      name: 'pricingVolatility'
    }
  },
  {
    rowDescriptor: 'Hedge Type',
    rowInputProps: {
      name: 'hedgeType',
      placeholder: 'Select Hedge Type',
      type: 'comboBox',
      options: [
        {
          name: 'Select Hedge Type',
          unavailable: true
        },
        // All hedge types are available at this point. There is a filter function that will remove 'NDF' if the
        // currency pair is a fix currency, see: components/table/utils/filterHedgeTypeOptions/filterHedgeTypeOptions.ts
        ...generateOptionsFromStringEnum(Enum_Axe_Hedgetype_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'Min. Notional',
    rowInputProps: {
      name: 'minimumNotionalAmount',
      type: 'number',
      step: 100000,
      placeholder: '',
      min: 0,
      decimalScale: 0,
      useFastKeys: true
    }
  }
]

export const tableRowGreeks: Array<VanillaFieldRow> = [
  {
    rowDescriptor: 'Premium',
    rowInputProps: {
      currencySelector: true,
      name: 'premium',
      type: 'number',
      placeholder: '',
      disableNumeric: true
    }
  },
  {
    rowDescriptor: 'Premium Date',
    rowDisabled: true,
    rowInputProps: {
      name: 'premiumDate',
      placeholder: '',
      type: 'date'
    }
  },
  {
    rowDescriptor: 'Delta',
    rowDisabled: true,
    rowInputProps: {
      name: 'delta',
      type: 'number',
      step: 10000,
      placeholder: '',
      decimalScale: 0
    }
  },
  {
    rowDescriptor: 'Gamma',
    rowDisabled: true,
    rowInputProps: {
      name: 'gamma',
      type: 'number',
      placeholder: ''
    }
  },
  {
    rowDescriptor: 'Vega',
    rowDisabled: true,
    rowInputProps: {
      name: 'vega',
      type: 'number',
      placeholder: ''
    }
  }
]
