import {
  buySellRow,
  ccyPairRow,
  expiryDateRow,
  strikeRow,
  volatilityRow,
  notionalRow
} from '../../../../../../forms/config/tableRows/tableRows'

const tableRows = [
  buySellRow,
  ccyPairRow,
  expiryDateRow,
  strikeRow,
  volatilityRow,
  notionalRow
]

export default tableRows
