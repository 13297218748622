import { UserProfile } from '@auth0/nextjs-auth0/client'
import { FormikHelpers, FormikProps } from 'formik'
import merge from 'lodash/merge'
import toast from 'react-hot-toast'
import { UseMutationConfig } from 'react-relay'
import { createAxePricingTiers } from '../../../../../utils/createAxePricingTiers/createAxePricingTiers'
import { createAxeUserTiersForAxe } from '../../../../../utils/createAxeUserTiersForAxe/createAxeUserTiersForAxe'
import { omitNonAxeSpecificFormFields } from '../../../../../utils/omitNonAxeSpecificFormFields/omitNonAxeSpecificFormFields'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import {
  validationSchema as strategyValidationSchemas,
  strategyValuesGenerator,
  Strategy,
  allInitialStrategyValues,
  StrategyFormValues
} from '../../components/axeStrategyForm/axeStrategyForm'
import { createAxeDialogButtonMutation } from '../../__generated__/createAxeDialogButtonMutation.graphql'
import {
  parseCreateAxeValues,
  parseAxeLegValues,
  initialValues
} from '../../createAxeDialogButton'
import schema from '../../schema.yup'
import * as Yup from 'yup'
import {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useMemo,
  RefObject
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Disposable } from 'relay-runtime'

type UseCreateAxeStepperParams = {
  commitMutation: (
    config: UseMutationConfig<createAxeDialogButtonMutation>
  ) => Disposable
  formikRef: RefObject<FormikProps<AxeFormData>>
  setOpen: Dispatch<SetStateAction<boolean>>
  showStrategyForm?: boolean
  subOrg: string
  user?: UserProfile
}

export default function useCreateAxeStepper({
  commitMutation,
  formikRef,
  setOpen,
  showStrategyForm,
  subOrg,
  user
}: UseCreateAxeStepperParams) {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const handleStrategySubmit = useCallback(
    (
      values: StrategyFormValues,
      formikHelpers: FormikHelpers<StrategyFormValues>
    ) => {
      const strategyValues =
        strategyValuesGenerator[values.strategy as Strategy](values)

      formikHelpers.setValues(merge({}, values, strategyValues))
      formikHelpers.setTouched({})
      formikHelpers.setErrors({})

      setCurrentStep(currentStep + 1)
    },
    [currentStep, setCurrentStep]
  )

  const handleAxeSubmit = useCallback(() => {
    setCurrentStep(currentStep + 1)
  }, [currentStep, setCurrentStep])

  const handleTierSubmit = useCallback(
    async (values: AxeFormData) => {
      toast.promise(
        new Promise((resolve, reject) => {
          const axeId = uuidv4()
          const leg1Values = values.legs[0]

          const axeValues = parseCreateAxeValues({
            axeAuthor: values.axeAuthor || '',
            axeAuthorSubOrganizationId: subOrg || '',
            axeAuthorOrganizationId: user?.org_id || '',
            id: axeId
          })

          // As pricingVolatilityT2 and pricingVolatilityT3 are not saved in
          // the Axe table We need to remove them from the values object
          // before sending to the server
          const axeValuesWithoutKeys = omitNonAxeSpecificFormFields(axeValues)

          const axeLegs = values.legs.map((leg, index) => {
            const legValues = parseAxeLegValues(leg)
            const legValuesWithoutKeys = omitNonAxeSpecificFormFields(legValues)

            return {
              ...legValuesWithoutKeys,
              orderIndex: index + 1
            }
          })
          const userTierData = createAxeUserTiersForAxe(
            axeId,
            user?.sub || '',
            values.tiers || [],
            values.removedSubOrgIds || []
          )
          commitMutation({
            variables: {
              axe: {
                ...axeValuesWithoutKeys,
                axe_legs: {
                  data: axeLegs
                }
              },
              axePricingTiers: createAxePricingTiers(
                axeId,
                leg1Values.pricingVolatility,
                leg1Values.pricingVolatilityT2 || leg1Values.pricingVolatility,
                leg1Values.pricingVolatilityT3 || leg1Values.pricingVolatility,
                values.tiers || []
              ),
              userTiers: userTierData
            },
            onError: (error) => {
              console.error(error)
              reject(error)
            },
            onCompleted: (value) => {
              resolve(value)
              setOpen(false)
              formikRef.current?.resetForm()
            }
          })
        }),
        {
          loading: 'Creating Axe...',
          success: 'New Axe Created',
          error: 'Error creating axe. Please try again.'
        }
      )
    },
    [commitMutation, setOpen, subOrg, user, formikRef]
  )

  const dynamicInitialValues = useMemo(() => {
    const values = initialValues

    if (user?.sub) {
      values.axeAuthor = btoa(`[1, "public", "users", "${user.sub}"]`)
    }

    if (showStrategyForm) {
      merge(values, allInitialStrategyValues, { strategy: Strategy.STRADDLE })
    }

    return values
  }, [showStrategyForm, user])

  const validationSchema = () =>
    Yup.lazy((values) => {
      if (showStrategyForm && currentStep === 0) {
        return strategyValidationSchemas[values.strategy as Strategy]
      }

      return schema
    })

  const onSubmit = useMemo(() => {
    return showStrategyForm
      ? [handleStrategySubmit, handleAxeSubmit, handleTierSubmit]
      : [handleAxeSubmit, handleTierSubmit]
  }, [
    handleAxeSubmit,
    handleStrategySubmit,
    handleTierSubmit,
    showStrategyForm
  ])

  return {
    formik: {
      initialValues: dynamicInitialValues,
      onSubmit: onSubmit[currentStep] as (
        values: AxeFormData,
        formikHelpers?: FormikHelpers<AxeFormData>
      ) => Promise<void>,
      validationSchema
    },
    stepper: {
      currentStep,
      goToStep: (index: number) => {
        if (index === -1) {
          setCurrentStep(currentStep - 1)
        } else {
          setCurrentStep(index)
        }
      }
    }
  }
}
