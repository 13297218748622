import { diff } from 'deep-diff'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import {
  AxeFormData,
  AxeFormLegData
} from '../dialog/updateAxeDialog/updateAxeDialog'
import React, { useCallback, useRef } from 'react'
import defaultConfig, { FormDataProcessorConfig } from './config/config'
import useProcessFieldConfig from './hooks/useProcessFieldConfig/useProcessFieldConfig'
import { useWorkerContext } from '../../context/marketDataWorkerContext/marketDataWorkerContext'
import { useEffectOnce } from 'react-use'
import isNil from 'lodash/isNil'
import { Axes } from '../../gql'
import isDiffEditProperty from './utils/isDiffEditProperty/isDiffEditProperty'
import isDiffNewArray from './utils/isDiffNewArray/isDiffNewArray'
import { rfqAxeDialog_rfqFragment$data } from '../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_rfqFragment.graphql'
import useHighlightUpdatedValues from './hooks/useHighlightUpdatedValues/useHighlightUpdatedValues'
import { rfqAxeDialog_axeFragment$data } from '../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_axeFragment.graphql'

export type FormDataProcessorFormType = 'createAxe' | 'updateAxe' | 'rfq'

type FormDataProcessorProps = {
  allowMarketDataUpdates?: boolean
  config?: FormDataProcessorConfig
  children: React.ReactNode
  formType?: FormDataProcessorFormType
  axeData?: rfqAxeDialog_axeFragment$data
  rfqData?: rfqAxeDialog_rfqFragment$data
  showUpdatedValueHighlights?: boolean
}

/**
 * The FormDataProcessor component is responsible for updating data when form values change.
 * It listens for changes in the form and applies the appropriate data manipulation logic.
 */
const FormDataProcessor = ({
  allowMarketDataUpdates = true,
  children,
  config = defaultConfig,
  formType = 'createAxe',
  axeData,
  rfqData,
  showUpdatedValueHighlights
}: FormDataProcessorProps) => {
  const { setValues, values, validateForm } = useFormikContext<AxeFormData>()
  const processFieldConfig = useProcessFieldConfig(config, formType, axeData)
  const { getCachedMarketValues, getMarketValues } = useWorkerContext()

  const prevValues = useRef(values)

  const highlightUpdatedValues = useHighlightUpdatedValues({
    rfqData,
    showUpdatedValueHighlights
  })

  // This function processes the data for a specific leg and returns the values to update.
  const processLegData = useCallback(
    (index?: number) => {
      const valuesToUpdate = {} as AxeFormData

      let legsToProcess: AxeFormLegData[] = []

      if (isNil(index)) {
        legsToProcess = [...values.legs]
      } else {
        legsToProcess[index] = values.legs[index]
      }

      legsToProcess.forEach((leg, index) => {
        Object.keys(leg).forEach((fieldName) => {
          const processedField = processFieldConfig(
            ['legs', index, fieldName],
            // Pass in the `valuesToUpdate` object to ensure we are operating on the latest processed values (Formik
            // values + `valuesToUpdate`) rather than just Formik values, which are not updated until the end of the
            // useEffect hook.
            valuesToUpdate
          )

          merge(valuesToUpdate, processedField?.values)
        })
      })

      return valuesToUpdate
    },
    [processFieldConfig, values]
  )

  // This function updates the Formik values with the new values (if present) and updates prevValues.
  const updateValues = useCallback(
    (valuesToUpdate: AxeFormData) => {
      if (!isEmpty(valuesToUpdate)) {
        const newValues = merge({}, values, valuesToUpdate)
        setValues(newValues)
        prevValues.current = newValues
      } else {
        prevValues.current = values
      }
    },
    [setValues, values]
  )

  // On the first render, we want to process all the initial data
  useEffectOnce(() => {
    if (!values?.legs) return

    const valuesToUpdate = processLegData()

    updateValues(valuesToUpdate)

    highlightUpdatedValues(prevValues.current)

    if (allowMarketDataUpdates) {
      // If getMarketValues is available, call it to fetch market data.
      // `prevValues.current` holds the latest values after the updateValues call, as `values` is set via state and cannot be used directly.
      getMarketValues?.({
        values: prevValues.current as AxeFormData,
        refreshAll: true
      })
    }
  })

  /**
   * This effect listens for changes in form values and applies data manipulation logic based on the configuration and
   * updates the form values accordingly.
   */
  React.useEffect(() => {
    const valuesDiff = diff(prevValues.current, values)

    // If there are differences between the previous and current values then we want to process data
    if (valuesDiff && !isEmpty(valuesDiff)) {
      const valuesToUpdate = {} as AxeFormData

      valuesDiff.forEach((diff) => {
        if (isDiffNewArray(diff, 'legs')) {
          const processedLegData = processLegData(diff.index)
          merge(valuesToUpdate, processedLegData)
        }
        // TODO: Shift input state cleanup when deleting legs (OPT-1516) -> else if (isDiffDeleteArray(diff, 'legs')) {}
        else if (isDiffEditProperty(diff)) {
          const processedField = processFieldConfig(diff.path, valuesToUpdate)

          merge(valuesToUpdate, processedField?.values)
        }
      })

      updateValues(valuesToUpdate)

      highlightUpdatedValues(prevValues.current)

      // Validate the form after updating the values
      validateForm()

      if (allowMarketDataUpdates) {
        // If getCachedMarketValues is available, call it to fetch market data.
        // `prevValues.current` holds the latest values after the updateValues call, as `values` is set via state and cannot be used directly.
        getCachedMarketValues?.(prevValues.current as unknown as Axes)
      }
    }
  }, [
    allowMarketDataUpdates,
    getCachedMarketValues,
    highlightUpdatedValues,
    processFieldConfig,
    processLegData,
    updateValues,
    validateForm,
    values
  ])

  return <>{children}</>
}

export default FormDataProcessor
