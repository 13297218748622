import {
  buySellRow,
  ccyPairRow,
  expiryDateRow,
  strikeRow,
  volatilityRow,
  notionalRow
} from '../../../../../../forms/config/tableRows/tableRows'
import { VanillaFormNames } from '../../../../../../input/input'
import { VanillaFieldRow } from '../../../../tableRows'

const tableRows = [
  buySellRow,
  ccyPairRow,
  expiryDateRow,
  {
    rowDescriptor: 'Strike 1',
    rowInputProps: {
      ...strikeRow.rowInputProps,
      name: 'strike1' as VanillaFormNames
    }
  } as VanillaFieldRow,
  {
    rowDescriptor: 'Strike 2',
    rowInputProps: {
      ...strikeRow.rowInputProps,
      name: 'strike2' as VanillaFormNames
    }
  } as VanillaFieldRow,
  volatilityRow,
  notionalRow
]

export default tableRows
