import React from 'react'
import ComboBox, { ComboBoxProps } from '../comboBox'
import { InputSelectOption } from '../../../types/inputSelect'
import useFieldSync from '../../../hooks/useFieldSync/useFieldSync'
import { InputVariant } from '../../input/input'
import { useFragment, graphql } from 'react-relay'
import { cutComboBoxExpiryCuts$key } from './__generated__/cutComboBoxExpiryCuts.graphql'

export const ExpiryCutsComboBoxFragment = graphql`
  fragment cutComboBoxExpiryCuts on expiry_cutsConnection {
    edges {
      node {
        code
      }
    }
  }
`

interface CutComboBoxProps extends ComboBoxProps {
  expiryCutsConnection?: cutComboBoxExpiryCuts$key
}

const CutComboBox = ({ fieldArrayPrefix, ...props }: CutComboBoxProps) => {
  const expiryCutsFragment = useFragment(
    ExpiryCutsComboBoxFragment,
    props.expiryCutsConnection
  )
  const [options, setOptions] = React.useState(props.options)

  React.useEffect(() => {
    const expiryCuts = expiryCutsFragment
      ? expiryCutsFragment.edges.map((edge) => edge.node)
      : []
    let options: InputSelectOption[] = []

    if (!expiryCuts || expiryCuts.length === 0) {
      options = [
        {
          name: 'Error: No cuts fetched',
          value: 'error'
        }
      ]
    } else {
      options = expiryCuts
        .sort()
        .reverse()
        .map(({ code }) => ({
          name: code,
          value: code
        }))
      options.unshift({
        name: 'Select Cut',
        unavailable: true
      })
    }

    setOptions([...options])
  }, [expiryCutsFragment])

  const { isDisabled } = useFieldSync(props.name)

  return (
    <ComboBox
      {...props}
      options={options}
      disabled={props.disabled || isDisabled}
      variant={InputVariant.CELL}
    />
  )
}

export default CutComboBox
